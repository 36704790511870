/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum COUNTRY_CODE {
    SG = 'SG',
    MY = 'MY'
}

export type ProfileInput = {
    id: string;
    email: string;
    phone?: string | null;
    contact_method?: Array<CONTACT_METHOD> | null;
    name: string;
    family_name: string;
    company?: string | null;
    group: Array<string>;
    former_group?: Array<string | null> | null;
};

export enum CONTACT_METHOD {
    WHATSAPP = 'WHATSAPP',
    EMAIL = 'EMAIL',
    PHONE = 'PHONE'
}

export type Profile = {
    __typename: 'Profile';
    id: string;
    email: string;
    name: string;
    phone?: string | null;
    contact_method?: Array<CONTACT_METHOD> | null;
    family_name: string;
    company?: string | null;
    group: Array<string>;
    created: string;
};

export type SetLeadInput = {
    id: string;
    validated: LEAD_VALIDATION;
    engagement: LEAD_ENGAGEMENT;
    name: string;
    family_name?: string | null;
    email: string;
    former_email: string;
    phone?: string | null;
    address: string;
    postal_code?: string | null;
    type: BUILDING_TYPE;
    roof_type: ROOF_MATERIAL;
    product: PRODUCT;
    whatsapp_template: string;
    email_template: string;
    tags: Array<LABELS>;
    term?: TENDER_SELECTION | null;
    waitlist?: boolean | null;
    size: number;
    state?: string | null;
    meter_box?: METER_BOX | null;
    utilities: number;
};

export enum LEAD_VALIDATION {
    PENDING = 'PENDING',
    VALIDATED = 'VALIDATED',
    INVALIDATED = 'INVALIDATED'
}

export enum LEAD_ENGAGEMENT {
    NO_STATUS = 'NO_STATUS',
    UNENGAGED = 'UNENGAGED',
    CLOSED_DISENGAGED = 'CLOSED_DISENGAGED'
}

export enum BUILDING_TYPE {
    LANDED_HOUSE = 'LANDED_HOUSE',
    CONDOMINIUM = 'CONDOMINIUM',
    HDB_FLAT = 'HDB_FLAT',
    RENTED_COMMERCIAL_PROPERTY = 'RENTED_COMMERCIAL_PROPERTY',
    OWNED_COMMERCIAL_PROPERTY = 'OWNED_COMMERCIAL_PROPERTY'
}

export enum ROOF_MATERIAL {
    METAL = 'METAL',
    TILES_SIMPLE = 'TILES_SIMPLE',
    TILES_COMPLEX = 'TILES_COMPLEX',
    TILES = 'TILES',
    CONCRETE = 'CONCRETE',
    MIXED = 'MIXED',
    OTHER = 'OTHER',
    NO_DATA = 'NO_DATA'
}

export enum PRODUCT {
    RTO = 'RTO',
    QC = 'QC',
    PPA = 'PPA',
    DP = 'DP'
}

export enum LABELS {
    RTO = 'RTO',
    RTO_SIMULATOR = 'RTO_SIMULATOR',
    REFERRAL = 'REFERRAL',
    ADMIN_QUALIFIED = 'ADMIN_QUALIFIED',
    ACCOUNT_QUALIFIED = 'ACCOUNT_QUALIFIED',
    RTO_WAITLIST = 'RTO_WAITLIST',
    RTO_WAITLIST_USER = 'RTO_WAITLIST_USER',
    EXIST = 'EXIST',
    PRIORITY = 'PRIORITY'
}

export enum TENDER_SELECTION {
    TEN_YEAR = 'TEN_YEAR',
    FIVE_YEAR = 'FIVE_YEAR',
    UPFRONT = 'UPFRONT'
}

export enum METER_BOX {
    SINGLE_PHASE = 'SINGLE_PHASE',
    THREE_PHASE = 'THREE_PHASE',
    USER_UNSURE = 'USER_UNSURE'
}

export type GroupLead = {
    __typename: 'GroupLead';
    id: string;
    address: string;
    name: string;
    family_name?: string | null;
    email: string;
    phone?: string | null;
    company?: string | null;
    type: BUILDING_TYPE;
    roof_type: ROOF_MATERIAL;
    retailer?: string | null;
    utilities: number;
    meter_box?: METER_BOX | null;
    timeframe: number;
    installation_size: number;
    created: string;
    updated?: string | null;
    prospect_id?: string | null;
    src: number;
    validated: LEAD_VALIDATION;
    engagement?: LEAD_ENGAGEMENT | null;
    postal_code?: string | null;
    hubspot?: Hubspot | null;
    product: PRODUCT;
    tags?: Array<LABELS> | null;
    trengo?: Trengo | null;
    sendgrid?: Sendgrid | null;
    lat?: number | null;
    lng?: number | null;
    polygon?: Array<Polygon> | null;
    term?: TENDER_SELECTION | null;
    waitlist?: boolean | null;
    city?: string | null;
    state?: string | null;
};

export type Hubspot = {
    __typename: 'Hubspot';
    contact_id?: string | null;
    deal_id?: string | null;
    error?: ERROR | null;
};

export enum ERROR {
    INVALID_WHATSAPP = 'INVALID_WHATSAPP',
    INVALID_EMAIL = 'INVALID_EMAIL',
    INVALID_TICKET = 'INVALID_TICKET'
}

export type Trengo = {
    __typename: 'Trengo';
    tickets: Array<Ticket>;
    ticket_id: Array<number | null>;
};

export type Ticket = {
    __typename: 'Ticket';
    id?: number | null;
    contact?: number | null;
    profile?: number | null;
    directory: string;
    channel: CHANNELS;
    type: CONTACT_TYPE;
    template?: TEMPLATE | null;
    error?: ERROR | null;
    params?: Array<WhatsappParam> | null;
};

export enum CHANNELS {
    WA_BUSINESS = 'WA_BUSINESS',
    EMAIL = 'EMAIL',
    VOIP = 'VOIP'
}

export enum CONTACT_TYPE {
    MAIN = 'MAIN',
    CONTRACTING = 'CONTRACTING',
    BILLING = 'BILLING',
    PARTNERS = 'PARTNERS',
    OTHERS = 'OTHERS'
}

export enum TEMPLATE {
    RTO = 'RTO',
    TILEROOF = 'TILEROOF',
    CLASSIC = 'CLASSIC',
    CI = 'CI',
    CONTACTED = 'CONTACTED',
    SIMULATOR_RESIDENTIAL = 'SIMULATOR_RESIDENTIAL',
    SIMULATOR_COMMERCIAL = 'SIMULATOR_COMMERCIAL'
}

export type WhatsappParam = {
    __typename: 'WhatsappParam';
    key: string;
    value: string;
};

export type Sendgrid = {
    __typename: 'Sendgrid';
    template?: TEMPLATE | null;
};

export type Polygon = {
    __typename: 'Polygon';
    lng: number;
    lat: number;
};

export type setEpcInput = {
    value?: string | null;
    label: string;
};

export type EpcEntity = {
    __typename: 'EpcEntity';
    label: string;
    value: string;
    stats: Array<number>;
    total: number;
};

export type EpcInput = {
    index?: number | null;
    company: CompanyInput;
    status: EPC_STATUS;
    capacity?: number | null;
    cost?: number | null;
    module?: string | null;
    panel_number?: number | null;
    inverter?: string | null;
    inverter_number?: number | null;
    scaffolding?: SCAFFOLDING | null;
    generated?: number | null;
    docs?: Array<DocInput> | null;
    comment?: string | null;
    remark?: string | null;
    created?: string | null;
    updated?: string | null;
    delivery: string;
    reports?: Array<ReportInput> | null;
};

export type CompanyInput = {
    value: string;
    label: string;
};

export enum EPC_STATUS {
    REQUESTED_WITH_EMAIL = 'REQUESTED_WITH_EMAIL',
    REQUESTED_NO_EMAIL = 'REQUESTED_NO_EMAIL',
    REQUOTE_WITH_EMAIL = 'REQUOTE_WITH_EMAIL',
    REQUOTE_NO_EMAIL = 'REQUOTE_NO_EMAIL',
    QUOTE_RECEIVED = 'QUOTE_RECEIVED',
    PROPOSAL_SEND = 'PROPOSAL_SEND',
    SHORTLISTED = 'SHORTLISTED',
    REVISIT = 'REVISIT',
    REJECTED = 'REJECTED',
    CLOSE_WON = 'CLOSE_WON',
    CLOSE_LOST = 'CLOSE_LOST',
    TIMEOUT = 'TIMEOUT'
}

export enum SCAFFOLDING {
    YES = 'YES',
    NO = 'NO',
    TO_CONFIRM = 'TO_CONFIRM'
}

export type DocInput = {
    name: string;
    type: string;
    link?: string | null;
    category?: DOC_CATEGORY | null;
    deleted?: boolean | null;
    updated_at?: string | null;
};

export enum DOC_CATEGORY {
    METER = 'METER',
    BILL = 'BILL',
    SWITCHBOARD = 'SWITCHBOARD',
    ROOF = 'ROOF',
    PLAN = 'PLAN',
    CREDIT_REPORT = 'CREDIT_REPORT',
    LAND_TITLE = 'LAND_TITLE',
    PROJECT_SUMMARY = 'PROJECT_SUMMARY',
    SIGNED_CONTRACT = 'SIGNED_CONTRACT',
    SIGNED_SP = 'SIGNED_SP',
    GIRO = 'GIRO',
    PROJECT = 'PROJECT',
    PANELS = 'PANELS',
    INVERTER = 'INVERTER',
    DB_BOX = 'DB_BOX',
    OTHER = 'OTHER',
    SP_INSPECTION = 'SP_INSPECTION',
    SP_METER = 'SP_METER',
    CTOS_REPORT = 'CTOS_REPORT',
    CONTACT = 'CONTACT',
    ALTERNATIVE_CONTACT = 'ALTERNATIVE_CONTACT',
    PROJECT_SUMMARY_WORK_ORDER = 'PROJECT_SUMMARY_WORK_ORDER',
    NEM_APPLICATION = 'NEM_APPLICATION',
    PRE_INSTALLATION = 'PRE_INSTALLATION',
    VOLTAGE_TEST_REPORT = 'VOLTAGE_TEST_REPORT',
    PV_LAYOUT_DRAWING_AND_SLD = 'PV_LAYOUT_DRAWING_AND_SLD',
    PANEL_AND_INVERTER_WARRANTY = 'PANEL_AND_INVERTER_WARRANTY',
    TNB_WELCOME_LETTER = 'TNB_WELCOME_LETTER',
    SEDA_COMPLETION_REPORT = 'SEDA_COMPLETION_REPORT',
    NEM_CERTIFICATE = 'NEM_CERTIFICATE',
    PROOF_OF_DEPOSIT_PAYMENT = 'PROOF_OF_DEPOSIT_PAYMENT',
    MOC_LETTER = 'MOC_LETTER'
}

export type ReportInput = {
    date: string;
    text: string;
};

export type Epc = {
    __typename: 'Epc';
    company: Company;
    index: number;
    status: EPC_STATUS;
    capacity?: number | null;
    cost?: number | null;
    module?: string | null;
    panel_number?: number | null;
    inverter?: string | null;
    inverter_number?: number | null;
    scaffolding?: SCAFFOLDING | null;
    generated?: number | null;
    docs?: Array<Doc> | null;
    comment?: string | null;
    remark?: string | null;
    created: string;
    updated: string;
    delivery: string;
    reports?: Array<Report> | null;
};

export type Company = {
    __typename: 'Company';
    value: string;
    label: string;
};

export type Doc = {
    __typename: 'Doc';
    name: string;
    type: string;
    link?: string | null;
    category?: DOC_CATEGORY | null;
    deleted?: boolean | null;
    updated_at?: string | null;
};

export type Report = {
    __typename: 'Report';
    date: string;
    text: string;
};

export type BillInput = {
    pk: string;
    invoice_id: string;
    paid?: string | null;
    status: BILL_STATUS;
    remark?: string | null;
};

export enum BILL_STATUS {
    PENDING = 'PENDING',
    PAID = 'PAID',
    TRIGGERED = 'TRIGGERED'
}

export type ListBills = {
    __typename: 'ListBills';
    bills: Array<Bills>;
    total?: number | null;
    keys?: Array<number> | null;
};

export type Bills = {
    __typename: 'Bills';
    customer_id?: string | null;
    pk: string;
    invoice_id: string;
    invoice_date?: string | null;
    send_date?: string | null;
    due_date?: string | null;
    period_start?: string | null;
    period_end?: string | null;
    paid?: string | null;
    raw?: number | null;
    deposit?: number | null;
    total?: number | null;
    GST?: number | null;
    status: BILL_STATUS;
    type?: BILL_TYPE | null;
    rebate?: number | null;
    name?: string | null;
    family_name?: string | null;
    address?: string | null;
    zip?: string | null;
    capacity?: number | null;
    investor?: INVESTOR | null;
    installation_date?: string | null;
    commissioning_date?: string | null;
    billing_start?: string | null;
    contract_end?: string | null;
    term?: TENDER_SELECTION | null;
    billing_currency?: string | null;
    method?: PAYMENT_METHOD | null;
    bank?: string | null;
    bank_account?: string | null;
    bank_owner?: string | null;
    remark?: string | null;
    items?: Array<BillItem> | null;
    counter?: number | null;
    country_code?: COUNTRY_CODE | null;
    epc?: string | null;
};

export enum BILL_TYPE {
    DEPOSIT = 'DEPOSIT',
    DOWN_PAYMENT = 'DOWN_PAYMENT',
    MONTHLY_PAYMENT = 'MONTHLY_PAYMENT',
    REFUND = 'REFUND',
    BUYDOWN = 'BUYDOWN',
    INVOICE = 'INVOICE'
}

export enum INVESTOR {
    FOMO = 'FOMO',
    VOLTON = 'VOLTON',
    FMAM = 'FMAM',
    HAO_SOLAR = 'HAO_SOLAR',
    GETSOLAR = 'GETSOLAR',
    TEST = 'TEST'
}

export enum PAYMENT_METHOD {
    GIRO = 'GIRO',
    UNVALIDATED_GIRO = 'UNVALIDATED_GIRO',
    STANDING_INSTRUCTION = 'STANDING_INSTRUCTION',
    CREDIT = 'CREDIT',
    CARD = 'CARD',
    FPX = 'FPX'
}

export type BillItem = {
    __typename: 'BillItem';
    description: string;
    amount: number;
};

export enum EXPORT {
    BILL = 'BILL',
    CUSTOMER = 'CUSTOMER'
}

export type IntegrationInput = {
    id: string;
    type: INTEGRATION_TYPE;
    keys?: Array<string> | null;
    error?: ERROR | null;
};

export enum INTEGRATION_TYPE {
    TRENGO = 'TRENGO',
    HUBSPOT = 'HUBSPOT'
}

export type Integration = {
    __typename: 'Integration';
    id: string;
    type: INTEGRATION_TYPE;
    keys?: Array<string> | null;
    error?: ERROR | null;
    country_code: COUNTRY_CODE;
};

export type VendeeInput = {
    id?: string | null;
    product?: PRODUCT | null;
    owner?: string | null;
    address?: string | null;
    state?: string | null;
    zip?: string | null;
    directory?: DirectoryInput | null;
    tags?: Array<LABELS> | null;
    simulation?: SimulationInput | null;
    site?: SiteInput | null;
    proffer?: ProfferInput | null;
    status?: PROSPECT_STATUS | null;
    docs: Array<DocInput>;
    tally?: TallyInput | null;
    created: CreatedInput;
};

export type DirectoryInput = {
    contacts: Array<ContactInput>;
};

export type ContactInput = {
    id?: string | null;
    name: string;
    family_name: string;
    company?: string | null;
    email?: string | null;
    phone?: string | null;
    method?: Array<CONTACT_METHOD> | null;
    type: CONTACT_TYPE;
};

export type SimulationInput = {
    timeframe?: number | null;
    installation: InstallationInput;
    savings: SavingsInput;
    impact: ImpactInput;
};

export type InstallationInput = {
    roi: number;
    cost: HighLowInput;
    generated: number;
    size: number;
    lease: string;
};

export type HighLowInput = {
    high: number;
    low: number;
};

export type SavingsInput = {
    utilities: HighLowInput;
    ppa: HighLowInput;
};

export type ImpactInput = {
    cars: number;
    trees: number;
    co2: number;
};

export type SiteInput = {
    type: BUILDING_TYPE;
    storeys?: number | null;
    height?: number | null;
    eui?: number | null;
    energy?: ENERGY | null;
    consumption: number;
    material: ROOF_MATERIAL;
    area: number;
    irradiation: number;
    utilities: UtilitiesInput;
    retailer?: RETAILER | null;
    daytime_consumption: number;
    daytime_consumption_rate: CONSUMPTION;
    meter_box?: METER_BOX | null;
};

export enum ENERGY {
    AVERAGE = 'AVERAGE',
    INTENSIVE = 'INTENSIVE',
    LOW = 'LOW'
}

export type UtilitiesInput = {
    input: number;
    calculated: number;
};

export enum RETAILER {
    SPGROUP = 'SPGROUP',
    GENECO = 'GENECO',
    KEPPEL_ELECTRIC = 'KEPPEL_ELECTRIC',
    SENOKO_ENERGY = 'SENOKO_ENERGY',
    UNION_POWER = 'UNION_POWER',
    TUAS_POWER_SUPPLY = 'TUAS_POWER_SUPPLY',
    SEMBCORP_POWER = 'SEMBCORP_POWER',
    DIAMOND_ELECTRIC = 'DIAMOND_ELECTRIC',
    PACIFIC_LIGHT_ENERGY = 'PACIFIC_LIGHT_ENERGY',
    SUNSEAP_ENERGY = 'SUNSEAP_ENERGY',
    OTHER = 'OTHER',
    NO_DATA = 'NO_DATA'
}

export enum CONSUMPTION {
    MORNING_AND_NIGHT = 'MORNING_AND_NIGHT',
    EQUALLY_THROUGHOUT_DAY = 'EQUALLY_THROUGHOUT_DAY',
    WEEKDAY_OFFICE = 'WEEKDAY_OFFICE',
    WEEKDAY_OFFICE_EXTENDED = 'WEEKDAY_OFFICE_EXTENDED',
    EVERYDAY_OFFICE = 'EVERYDAY_OFFICE',
    EVERYDAY_OFFICE_EXTENDED = 'EVERYDAY_OFFICE_EXTENDED',
    TWENTYFOUR_SEVEN = 'TWENTYFOUR_SEVEN',
    OTHER = 'OTHER',
    NO_DATA = 'NO_DATA'
}

export type ProfferInput = {
    comment?: string | null;
    sub_switchboard?: SUB_SWITCHBOARD | null;
    meter_box?: METER_BOX | null;
    occupancy?: OCCUPANCY | null;
    expected_date?: string | null;
    offers?: Array<TENDER_SELECTION> | null;
};

export enum SUB_SWITCHBOARD {
    STOREY = 'STOREY',
    GROUND_FLOOR_ONLY = 'GROUND_FLOOR_ONLY'
}

export enum OCCUPANCY {
    OCCUPY = 'OCCUPY',
    MOVING_IN = 'MOVING_IN'
}

export enum PROSPECT_STATUS {
    CONNECTED = 'CONNECTED',
    PROPOSAL_PENDING = 'PROPOSAL_PENDING',
    QUOTE_RECEIVED = 'QUOTE_RECEIVED',
    PROPOSAL_SAVED = 'PROPOSAL_SAVED',
    PROPOSAL_SENT = 'PROPOSAL_SENT',
    CONNECTED_TO_EPC = 'CONNECTED_TO_EPC',
    ADVISORY_CALL_COMPLETED = 'ADVISORY_CALL_COMPLETED',
    SITE_VISIT_ARRANGED = 'SITE_VISIT_ARRANGED',
    SITE_VISIT_COMPLETE = 'SITE_VISIT_COMPLETE',
    CLOSED_LOST = 'CLOSED_LOST',
    CLOSED_WON = 'CLOSED_WON',
    REVISIT = 'REVISIT',
    REVISIT_LOW_PRIORITY = 'REVISIT_LOW_PRIORITY',
    FAKE_LEADS = 'FAKE_LEADS'
}

export type TallyInput = {
    installation?: string | null;
    commissioning?: string | null;
    deposit?: number | null;
    lease?: number | null;
    size?: number | null;
    term?: TENDER_SELECTION | null;
    investor?: INVESTOR | null;
    start?: string | null;
    end?: string | null;
    rebate?: number | null;
    payment?: PaymentInput | null;
    price?: number | null;
    capex?: number | null;
    status?: CUSTOMER_STATUS | null;
    epc?: CompanyInput | null;
    installation_start?: string | null;
    site_visit?: string | null;
    site_visit_type?: SITE_VISIT_TYPE | null;
    retailer?: RETAILER | null;
    contract?: FORM_STATUS | null;
    whatsapp?: boolean | null;
    ami?: AMI | null;
    sp?: FORM_STATUS | null;
    sp_no?: string | null;
    giro?: GIRO_STATUS | null;
    inverter?: boolean | null;
    inverter_type?: Array<string> | null;
    inverter_number?: number | null;
    panel?: Array<string> | null;
    panel_number?: number | null;
    tierPayment?: Array<PaymentTrancheInput> | null;
    buydown?: string | null;
    invoice_items?: Array<BillItemInput> | null;
    nem_application_status?: NEM_APPLICATION_STATUS | null;
    epc_added_to_whatsapp?: boolean | null;
    tnb_stamp_fee_status?: TNB_STAMP_FEE_STATUS | null;
    installation_status?: INSTALLATION_STATUS | null;
    tnb_meter_change_date?: string | null;
    system_turn_on_date?: string | null;
    ami_meter_change_date?: string | null;
    installation_scheduled?: boolean | null;
    top_date?: string | null;
    upload_to_epc?: boolean | null;
    e_giro?: EGiroInput | null;
};

export type PaymentInput = {
    method?: PAYMENT_METHOD | null;
    bank?: BANK | null;
    bank_account?: string | null;
    bank_owner?: string | null;
};

export enum BANK {
    CITIBANK = 'CITIBANK',
    DBS = 'DBS',
    HSBC = 'HSBC',
    MAYBANK = 'MAYBANK',
    OCBC = 'OCBC',
    STANDARD_CHARTERED = 'STANDARD_CHARTERED',
    UOB = 'UOB',
    POSB = 'POSB',
    BOC = 'BOC',
    ICBOC = 'ICBOC',
    CIMB = 'CIMB',
    RHB = 'RHB',
    HL = 'HL',
    PUBLIC = 'PUBLIC',
    AMBANK = 'AMBANK',
    AFFIN_ISLAMIC = 'AFFIN_ISLAMIC',
    AFFIN = 'AFFIN',
    ALLIANCE = 'ALLIANCE',
    AGROBANK = 'AGROBANK',
    ISLAM = 'ISLAM',
    RAKYAT = 'RAKYAT',
    MUAMALAT = 'MUAMALAT',
    BSN = 'BSN',
    KFH = 'KFH',
    MAYBANK_2U = 'MAYBANK_2U',
    MAYBANK_2E = 'MAYBANK_2E',
    TEST_A = 'TEST_A',
    TEST_B = 'TEST_B',
    TEST_C = 'TEST_C'
}

export enum CUSTOMER_STATUS {
    A_UNDEFINED = 'A_UNDEFINED',
    B_CLOSED_LOST = 'B_CLOSED_LOST',
    B_PAUSE = 'B_PAUSE',
    C_EPC_SITE_VISIT_PENDING = 'C_EPC_SITE_VISIT_PENDING',
    D_INVESTOR_APPROVAL_PENDING = 'D_INVESTOR_APPROVAL_PENDING',
    E_CONTRACT_SENT = 'E_CONTRACT_SENT',
    F_PAYMENT_PENDING = 'F_PAYMENT_PENDING',
    G_INSTALLATION_PENDING = 'G_INSTALLATION_PENDING',
    H_INSTALLATION_COMPLETE = 'H_INSTALLATION_COMPLETE',
    I_SYSTEM_COMMISSIONED = 'I_SYSTEM_COMMISSIONED'
}

export enum SITE_VISIT_TYPE {
    SALES = 'SALES',
    CUSTOMER = 'CUSTOMER',
    EPC = 'EPC'
}

export enum FORM_STATUS {
    PENDING = 'PENDING',
    SENT = 'SENT'
}

export enum AMI {
    NOT_REQUIRED = 'NOT_REQUIRED',
    PENDING = 'PENDING',
    REQUESTED = 'REQUESTED',
    APPROVED = 'APPROVED',
    COMPLETED = 'COMPLETED'
}

export enum GIRO_STATUS {
    MAILED_OUT_PENDING_RECEIPT = 'MAILED_OUT_PENDING_RECEIPT',
    PROCESSING_BY_BANK = 'PROCESSING_BY_BANK',
    BANK_APPROVED_AND_NOTIFIED = 'BANK_APPROVED_AND_NOTIFIED',
    BANK_REJECTED_AND_REMAILED = 'BANK_REJECTED_AND_REMAILED',
    E_GIRO_LINK_SENT = 'E_GIRO_LINK_SENT',
    NA_STANDING_INSTRUCTIONS = 'NA_STANDING_INSTRUCTIONS',
    NA_DIRECT_PURCHASE = 'NA_DIRECT_PURCHASE',
    NA_MANUAL_PAYMENT = 'NA_MANUAL_PAYMENT'
}

export type PaymentTrancheInput = {
    payment?: number | null;
    paid: boolean;
    issued: boolean;
};

export type BillItemInput = {
    description: string;
    amount: number;
};

export enum NEM_APPLICATION_STATUS {
    PENDING = 'PENDING',
    SENT = 'SENT',
    SUBMITTED_TO_EPC = 'SUBMITTED_TO_EPC',
    SUBMITTED_TO_SEDA = 'SUBMITTED_TO_SEDA',
    APPROVED = 'APPROVED'
}

export enum TNB_STAMP_FEE_STATUS {
    UNPAID = 'UNPAID',
    PAID = 'PAID'
}

export enum INSTALLATION_STATUS {
    NOT_STARTED = 'NOT_STARTED',
    STARTED = 'STARTED'
}

export type EGiroInput = {
    status?: E_GIRO_STATUS | null;
};

export enum E_GIRO_STATUS {
    REQUIRES_AUTHORIZATION = 'REQUIRES_AUTHORIZATION',
    LINK_SENT = 'LINK_SENT',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED'
}

export type CreatedInput = {
    vendee?: string | null;
    lead?: string | null;
    prospect?: string | null;
    customer?: string | null;
};

export type Vendee = {
    __typename: 'Vendee';
    country_code: COUNTRY_CODE;
    id: string;
    src?: SRC | null;
    ids?: Ids | null;
    product: PRODUCT;
    owner?: string | null;
    address: string;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    directory?: Directory | null;
    tags?: Array<LABELS> | null;
    simulation?: Simulation | null;
    site?: Site | null;
    proffer?: Proffer | null;
    integrations?: Integrations | null;
    status?: ProspectStatus | null;
    validated?: LEAD_VALIDATION | null;
    engagement?: LEAD_ENGAGEMENT | null;
    docs?: Array<Doc> | null;
    tally?: Tally | null;
    referral?: Referral | null;
    stages?: Array<Stage> | null;
    created: Created;
    updated?: string | null;
    comment?: string | null;
    cognito_user_id?: string | null;
    utm?: Utm | null;
};

export enum SRC {
    SIMULATOR = 'SIMULATOR',
    ADMIN = 'ADMIN',
    ACCOUNT = 'ACCOUNT',
    EPC = 'EPC',
    WIDGET = 'WIDGET',
    DEVOP = 'DEVOP',
    SYSTEM = 'SYSTEM',
    CUSTOMER_APP = 'CUSTOMER_APP'
}

export type Ids = {
    __typename: 'Ids';
    lead?: string | null;
    prospect?: string | null;
    customer?: string | null;
    internal?: string | null;
};

export type Directory = {
    __typename: 'Directory';
    contacts: Array<Contact>;
};

export type Contact = {
    __typename: 'Contact';
    id: string;
    name: string;
    family_name: string;
    company?: string | null;
    email?: string | null;
    phone?: string | null;
    method?: Array<CONTACT_METHOD> | null;
    type: CONTACT_TYPE;
};

export type Simulation = {
    __typename: 'Simulation';
    timeframe?: number | null;
    ip?: Ip | null;
    src?: number | null;
    installation: Installation;
    savings: Savings;
    impact: Impact;
    rtoOptions?: Array<RtoOption> | null;
};

export type Ip = {
    __typename: 'Ip';
    source?: string | null;
    country?: string | null;
};

export type Installation = {
    __typename: 'Installation';
    roi: number;
    cost: HighLow;
    generated: number;
    size: number;
    lease: string;
};

export type HighLow = {
    __typename: 'HighLow';
    high: number;
    low: number;
};

export type Savings = {
    __typename: 'Savings';
    utilities: HighLow;
    ppa: HighLow;
};

export type Impact = {
    __typename: 'Impact';
    cars: number;
    trees: number;
    co2: number;
};

export type RtoOption = {
    __typename: 'RtoOption';
    cost: number;
    payment: number;
};

export type Site = {
    __typename: 'Site';
    address_components?: Array<AddressComponent> | null;
    type: BUILDING_TYPE;
    storeys?: number | null;
    height?: number | null;
    eui?: number | null;
    energy?: ENERGY | null;
    consumption: number;
    location: Array<number>;
    osm_id?: number | null;
    material: ROOF_MATERIAL;
    polygon: Array<Polygon>;
    area: number;
    irradiation: number;
    utilities: Utilities;
    retailer?: RETAILER | null;
    daytime_consumption: number;
    daytime_consumption_rate: CONSUMPTION;
    meter_box?: METER_BOX | null;
};

export type AddressComponent = {
    __typename: 'AddressComponent';
    long_name: string;
    short_name: string;
    types: Array<string | null>;
};

export type Utilities = {
    __typename: 'Utilities';
    input?: number | null;
    calculated?: number | null;
};

export type Proffer = {
    __typename: 'Proffer';
    comment?: string | null;
    sub_switchboard?: SUB_SWITCHBOARD | null;
    meter_box?: METER_BOX | null;
    occupancy?: OCCUPANCY | null;
    expected_date?: string | null;
    offers?: Array<TENDER_SELECTION> | null;
    epc?: Array<Epc> | null;
    tender?: Tender | null;
    advisory_call_date?: string | null;
    advisory_call_time?: string | null;
};

export type Tender = {
    __typename: 'Tender';
    selection?: TENDER_SELECTION | null;
    url?: string | null;
    link?: string | null;
    summary: TenderSummary;
    options: Array<TenderOptions>;
    type: TENDER_TYPE;
};

export type TenderSummary = {
    __typename: 'TenderSummary';
    size: HighLow;
    generated: HighLow;
    cost?: HighLow | null;
    savings?: HighLow | null;
    roi?: HighLow | null;
    monthly_savings?: number | null;
};

export type TenderOptions = {
    __typename: 'TenderOptions';
    cost?: number | null;
    payment?: number | null;
    maintenance?: number | null;
    savings: number;
};

export enum TENDER_TYPE {
    UPFRONT = 'UPFRONT',
    RTO = 'RTO',
    TILEROOF = 'TILEROOF'
}

export type Integrations = {
    __typename: 'Integrations';
    trengo?: Trengo | null;
    hubspot?: Hubspot | null;
    sendgrid?: Sendgrid | null;
};

export type ProspectStatus = {
    __typename: 'ProspectStatus';
    value: PROSPECT_STATUS;
    date: string;
};

export type Tally = {
    __typename: 'Tally';
    installation?: string | null;
    commissioning?: string | null;
    deposit?: number | null;
    lease?: number | null;
    size?: number | null;
    term?: TENDER_SELECTION | null;
    investor?: INVESTOR | null;
    start?: string | null;
    end?: string | null;
    contract_end?: string | null;
    rebate?: number | null;
    payment?: Payment | null;
    bills?: Array<Bill> | null;
    currency?: string | null;
    status?: CUSTOMER_STATUS | null;
    epc?: Company | null;
    price?: number | null;
    capex?: number | null;
    installation_start?: string | null;
    site_visit?: string | null;
    site_visit_type?: SITE_VISIT_TYPE | null;
    retailer?: RETAILER | null;
    contract?: FORM_STATUS | null;
    whatsapp?: boolean | null;
    ami?: AMI | null;
    sp?: FORM_STATUS | null;
    sp_no?: string | null;
    giro?: GIRO_STATUS | null;
    inverter?: boolean | null;
    inverter_type?: Array<string> | null;
    inverter_number?: number | null;
    panel?: Array<string> | null;
    panel_number?: number | null;
    tierPayment?: Array<PaymentTranche> | null;
    buydown?: string | null;
    invoice_items?: Array<BillItem> | null;
    e_giro?: EGiro | null;
    curlec?: Curlec | null;
    nem_application_status?: NEM_APPLICATION_STATUS | null;
    epc_added_to_whatsapp?: boolean | null;
    tnb_stamp_fee_status?: TNB_STAMP_FEE_STATUS | null;
    installation_status?: INSTALLATION_STATUS | null;
    tnb_meter_change_date?: string | null;
    system_turn_on_date?: string | null;
    ami_meter_change_date?: string | null;
    installation_scheduled?: boolean | null;
    top_date?: string | null;
    upload_to_epc?: boolean | null;
};

export type Payment = {
    __typename: 'Payment';
    method?: PAYMENT_METHOD | null;
    bank?: BANK | null;
    bank_account?: string | null;
    bank_owner?: string | null;
};

export type Bill = {
    __typename: 'Bill';
    id: string;
    type: BILL_TYPE;
    status: BILL_STATUS;
    raw: number;
    total: number;
    GST: number;
    taxe: number;
    send_date: string;
    invoice_date: string;
    due_date: string;
    period_start?: string | null;
    period_end?: string | null;
    paid?: string | null;
    rebate?: number | null;
    method?: PAYMENT_METHOD | null;
    remark?: string | null;
    items?: Array<BillItem> | null;
    counter?: number | null;
};

export type PaymentTranche = {
    __typename: 'PaymentTranche';
    payment?: number | null;
    paid: boolean;
    issued: boolean;
};

export type EGiro = {
    __typename: 'EGiro';
    status?: E_GIRO_STATUS | null;
};

export type Curlec = {
    __typename: 'Curlec';
    status?: CURLEC_STATUS | null;
};

export enum CURLEC_STATUS {
    LINK_SENT = 'LINK_SENT',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED'
}

export type Referral = {
    __typename: 'Referral';
    name?: string | null;
};

export type Stage = {
    __typename: 'Stage';
    status: string;
    start: string;
    end?: string | null;
    duration?: number | null;
    category?: STAGE_CATEGORIES | null;
    id?: string | null;
};

export enum STAGE_CATEGORIES {
    LEAD = 'LEAD',
    PROSPECT = 'PROSPECT',
    CUSTOMER = 'CUSTOMER'
}

export type Created = {
    __typename: 'Created';
    vendee: string;
    lead?: string | null;
    prospect?: string | null;
    customer?: string | null;
};

export type Utm = {
    __typename: 'Utm';
    source?: string | null;
    medium?: string | null;
    campaign?: string | null;
    term?: string | null;
    content?: string | null;
};

export type LogInput = {
    id?: string | null;
    created?: string | null;
    content?: string | null;
    stage?: StagesInput | null;
    category: LOG_CATEGORIES;
    favourite?: boolean | null;
    editor_content?: ContentStateInput | null;
};

export type StagesInput = {
    type: PROSPECT_STATUS;
    product?: PRODUCT | null;
    comment?: string | null;
    date?: string | null;
    url?: string | null;
    select?: Array<string> | null;
    price?: number | null;
    commission?: number | null;
    size?: number | null;
    retailer?: RETAILER | null;
    investor?: INVESTOR | null;
    term?: TENDER_SELECTION | null;
    down_payment?: number | null;
    lease?: number | null;
    installation_date?: string | null;
    capex?: number | null;
    fee?: FeeInput | null;
    irr?: number | null;
    docs?: Array<DocInput> | null;
    tender?: TenderInput | null;
    epc?: CompanyInput | null;
    advisory_call_date?: string | null;
    advisory_call_time?: string | null;
};

export type FeeInput = {
    start?: number | null;
    end?: number | null;
    management?: number | null;
};

export type TenderInput = {
    selection?: TENDER_SELECTION | null;
    url?: string | null;
    link?: string | null;
    summary: TenderSummaryInput;
    options: Array<TenderOptionsInput>;
    type: TENDER_TYPE;
};

export type TenderSummaryInput = {
    size: HighLowInput;
    generated: HighLowInput;
    cost?: HighLowInput | null;
    savings?: HighLowInput | null;
    roi?: HighLowInput | null;
    monthly_savings?: number | null;
};

export type TenderOptionsInput = {
    cost?: number | null;
    payment?: number | null;
    maintenance?: number | null;
    savings: number;
};

export enum LOG_CATEGORIES {
    NOTE = 'NOTE',
    RECORD = 'RECORD',
    WA_BUSINESS = 'WA_BUSINESS',
    EMAIL = 'EMAIL',
    VOIP = 'VOIP',
    SITE_VISIT = 'SITE_VISIT',
    STAGE = 'STAGE'
}

export type ContentStateInput = {
    blocks?: Array<BlockInput | null> | null;
    entityMap?: Array<EntityInput | null> | null;
};

export type BlockInput = {
    key?: string | null;
    text?: string | null;
    type?: string | null;
    depth?: number | null;
    inlineStyleRanges?: Array<InlineStyleRangeInput | null> | null;
    entityRanges?: Array<EntityRangeInput | null> | null;
    data?: DataInput | null;
};

export type InlineStyleRangeInput = {
    offset?: number | null;
    length?: number | null;
    style?: string | null;
};

export type EntityRangeInput = {
    offset?: number | null;
    length?: number | null;
    key?: number | null;
};

export type DataInput = {
    id?: string | null;
};

export type EntityInput = {
    type?: string | null;
    mutability?: string | null;
    data?: EntityDataInput | null;
};

export type EntityDataInput = {
    mention?: MentionDataInput | null;
};

export type MentionDataInput = {
    value?: string | null;
    name?: string | null;
    label?: string | null;
    id?: string | null;
};

export type Log = {
    __typename: 'Log';
    id: string;
    author?: Author | null;
    created?: string | null;
    changes?: Array<Change | null> | null;
    content?: string | null;
    visit?: Visit | null;
    category: LOG_CATEGORIES;
    stage?: Stages | null;
    favourite?: boolean | null;
    editor_content?: ContentState | null;
};

export type Author = {
    __typename: 'Author';
    username?: string | null;
    src: SRC;
};

export type Change = {
    __typename: 'Change';
    op: OP;
    path: string;
    value?: string | null;
};

export enum OP {
    add = 'add',
    replace = 'replace',
    remove = 'remove',
    create = 'create'
}

export type Visit = {
    __typename: 'Visit';
    pitch: string;
    access: string;
    installation: string;
    inverter: string;
    cabling: string;
    timeline: string;
    other: string;
    docs: Array<Doc>;
};

export type Stages = {
    __typename: 'Stages';
    type: PROSPECT_STATUS;
    product?: PRODUCT | null;
    comment?: string | null;
    date?: string | null;
    url?: string | null;
    select?: Array<string> | null;
    price?: number | null;
    commission?: number | null;
    size?: number | null;
    retailer?: RETAILER | null;
    investor?: INVESTOR | null;
    term?: TENDER_SELECTION | null;
    down_payment?: number | null;
    lease?: number | null;
    installation_date?: string | null;
    capex?: number | null;
    fee?: Fee | null;
    irr?: number | null;
    docs?: Array<Doc> | null;
    tender?: Tender | null;
    epc?: Company | null;
    advisory_call_date?: string | null;
    advisory_call_time?: string | null;
};

export type Fee = {
    __typename: 'Fee';
    start?: number | null;
    end?: number | null;
    management?: number | null;
};

export type ContentState = {
    __typename: 'ContentState';
    blocks?: Array<Block | null> | null;
    entityMap?: Array<Entity | null> | null;
};

export type Block = {
    __typename: 'Block';
    key?: string | null;
    text?: string | null;
    type?: string | null;
    depth?: number | null;
    inlineStyleRanges?: Array<InlineStyleRange | null> | null;
    entityRanges?: Array<EntityRange | null> | null;
    data?: Data | null;
};

export type InlineStyleRange = {
    __typename: 'InlineStyleRange';
    offset?: number | null;
    length?: number | null;
    style?: string | null;
};

export type EntityRange = {
    __typename: 'EntityRange';
    offset?: number | null;
    length?: number | null;
    key?: number | null;
};

export type Data = {
    __typename: 'Data';
    id?: string | null;
};

export type Entity = {
    __typename: 'Entity';
    type?: string | null;
    mutability?: string | null;
    data?: EntityData | null;
};

export type EntityData = {
    __typename: 'EntityData';
    mention?: MentionData | null;
};

export type MentionData = {
    __typename: 'MentionData';
    value?: string | null;
    name?: string | null;
    label?: string | null;
    id?: string | null;
};

export type StatusInput = {
    status: PROSPECT_STATUS;
    vendee_id: string;
    product?: PRODUCT | null;
    comment?: string | null;
    date?: string | null;
    url?: string | null;
    select?: Array<string> | null;
    price?: number | null;
    commission?: number | null;
    size?: number | null;
    retailer?: RETAILER | null;
    investor?: INVESTOR | null;
    term?: TENDER_SELECTION | null;
    down_payment?: number | null;
    lease?: number | null;
    installation_date?: string | null;
    capex?: number | null;
    fee?: FeeInput | null;
    irr?: number | null;
    tender?: TenderInput | null;
    docs?: Array<DocInput> | null;
    send?: boolean | null;
    epc?: CompanyInput | null;
    advisory_call_date?: string | null;
    advisory_call_time?: string | null;
};

export type SetStatusReturn = {
    __typename: 'SetStatusReturn';
    log: Log;
    stages?: Array<Stage> | null;
    status: ProspectStatus;
};

export type SetMilestoneInput = {
    id: string;
    name?: string | null;
    family_name?: string | null;
    email?: string | null;
    phone?: string | null;
    tally: TallyInput;
    state?: string | null;
    docs?: Array<DocInput> | null;
    deposit?: boolean | null;
    deposit_paid?: string | null;
};

export enum USER_GROUP {
    super_admin = 'super_admin',
    admin = 'admin',
    epc = 'epc',
    investor = 'investor',
    sale = 'sale'
}

export enum SORT {
    ASC = 'ASC',
    DESC = 'DESC'
}

export enum USER_SORT_FIELD {
    NAME = 'NAME',
    FAMILY = 'FAMILY',
    EMAIL = 'EMAIL',
    COMPANY = 'COMPANY',
    GROUP = 'GROUP',
    CREATED = 'CREATED',
    CONTACT_METHOD = 'CONTACT_METHOD',
    PHONE = 'PHONE'
}

export type ListUsers = {
    __typename: 'ListUsers';
    total: number;
    users: Array<Profile>;
};

export enum LEAD_SORT_FIELD {
    BUILDING_TYPE = 'BUILDING_TYPE',
    ADDRESS = 'ADDRESS',
    POSTAL_CODE = 'POSTAL_CODE',
    CITY = 'CITY',
    STATE = 'STATE',
    NAME = 'NAME',
    FAMILY_NAME = 'FAMILY_NAME',
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
    RTO = 'RTO',
    TIMEFRAME = 'TIMEFRAME',
    INSTALLATION_SIZE = 'INSTALLATION_SIZE',
    UTILITY = 'UTILITY',
    CREATED = 'CREATED',
    UPDATED = 'UPDATED'
}

export type ListGroupLeads = {
    __typename: 'ListGroupLeads';
    total: number;
    groups: Array<GroupLeads>;
};

export type GroupLeads = {
    __typename: 'GroupLeads';
    total: number;
    leads: Array<GroupLead>;
};

export enum PROSPECT_SORT_FIELD {
    RTO = 'RTO',
    NAME = 'NAME',
    EMAIL = 'EMAIL',
    ADDRESS = 'ADDRESS',
    CITY = 'CITY',
    STATE = 'STATE',
    OWNER = 'OWNER',
    POSTAL_CODE = 'POSTAL_CODE',
    UTILITY = 'UTILITY',
    STATUS = 'STATUS',
    START_DATE = 'START_DATE',
    UPDATED = 'UPDATED',
    ROOF_MATERIAL = 'ROOF_MATERIAL'
}

export type ListVendees = {
    __typename: 'ListVendees';
    total: number;
    vendees: Array<Vendee>;
};

export enum CUSTOMER_SORT_FIELD {
    INTERNAL_ID = 'INTERNAL_ID',
    NAME = 'NAME',
    ADDRESS = 'ADDRESS',
    TERM = 'TERM',
    CONTRACT_NAME = 'CONTRACT_NAME',
    BILLING_START = 'BILLING_START',
    BILLING_END = 'BILLING_END'
}

export type ListCustomers = {
    __typename: 'ListCustomers';
    total: number;
    customers: Array<Vendee>;
};

export type Upload = {
    __typename: 'Upload';
    url: Array<string>;
    link: Array<string>;
    name: Array<string>;
};

export type ListSale = {
    __typename: 'ListSale';
    total: number;
    sales: Array<Profile>;
};

export enum BILL_SORT_FIELD {
    ADDRESS = 'ADDRESS',
    CUSTOMER_ID = 'CUSTOMER_ID',
    CUSTOMER_NAME = 'CUSTOMER_NAME',
    BANK_ACCOUNT = 'BANK_ACCOUNT',
    METHOD = 'METHOD',
    TYPE = 'TYPE',
    BILL_ID = 'BILL_ID',
    SEND_DATE = 'SEND_DATE',
    TOTAL = 'TOTAL',
    PAID = 'PAID',
    STATUS = 'STATUS',
    INVESTOR = 'INVESTOR',
    EPC = 'EPC',
    REBATE = 'REBATE'
}

export type ListActivities = {
    __typename: 'ListActivities';
    activity: Array<Activity>;
};

export type Activity = {
    __typename: 'Activity';
    count: number;
    status: PROSPECT_STATUS;
    owner: string;
};

export type ListActivityDurations = {
    __typename: 'ListActivityDurations';
    duration: Array<ActivityDuration>;
};

export type ActivityDuration = {
    __typename: 'ActivityDuration';
    avg: number;
    median: number;
    min: number;
    max: number;
    status: PROSPECT_STATUS;
    owner: string;
};

export type ListApportions = {
    __typename: 'ListApportions';
    apportion: Array<Apportion>;
};

export type Apportion = {
    __typename: 'Apportion';
    count: number;
    status: PROSPECT_STATUS;
    owner: string;
};

export enum MONTH {
    JAN = 'JAN',
    FEB = 'FEB',
    MAR = 'MAR',
    APR = 'APR',
    MAY = 'MAY',
    JUN = 'JUN',
    JUL = 'JUL',
    AUG = 'AUG',
    SEP = 'SEP',
    OCT = 'OCT',
    NOV = 'NOV',
    DEC = 'DEC'
}

export type ListLogs = {
    __typename: 'ListLogs';
    total: number;
    logs: Array<Log>;
};

export enum MILESTONE_SORT_FIELD {
    INTERNAL_ID = 'INTERNAL_ID',
    PRODUCT = 'PRODUCT',
    STATUS = 'STATUS',
    NAME = 'NAME',
    ADDRESS = 'ADDRESS',
    INVESTOR = 'INVESTOR',
    EPC = 'EPC',
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    TERM = 'TERM',
    SP_STATUS = 'SP_STATUS',
    GIRO_STATUS = 'GIRO_STATUS',
    SITE_VISIT_DATE = 'SITE_VISIT_DATE',
    AMI = 'AMI',
    CONTRACT_STATUS = 'CONTRACT_STATUS',
    NEM_STATUS = 'NEM_STATUS',
    EST_INSTALLATION_DATE = 'EST_INSTALLATION_DATE',
    E_MANDATE_STATUS = 'E_MANDATE_STATUS'
}

export type ListMilestones = {
    __typename: 'ListMilestones';
    total: number;
    milestones: Array<Milestones>;
};

export type Milestones = {
    __typename: 'Milestones';
    id: string;
    ids?: Ids | null;
    product: PRODUCT;
    address: string;
    zip?: string | null;
    state?: string | null;
    directory: Directory;
    tally?: Tally | null;
    docs?: Array<Doc> | null;
    created: string;
    updated: string;
    cognito_user_id?: string | null;
};

export type ClosedWonStageLog = {
    __typename: 'ClosedWonStageLog';
    id: string;
    country_code: COUNTRY_CODE;
    product?: PRODUCT | null;
    closed_won_date?: string | null;
    price?: number | null;
    commission?: number | null;
    size?: number | null;
    retailer?: RETAILER | null;
    investor?: INVESTOR | null;
    term?: TENDER_SELECTION | null;
    down_payment?: number | null;
    lease?: number | null;
    installation_date?: string | null;
    capex?: number | null;
    fee?: Fee | null;
    docs?: Array<Doc> | null;
    irr?: number | null;
    epc?: Company | null;
};

export enum NOTIFICATION_TYPE {
    TAG = 'TAG',
    NOTIFICATION = 'NOTIFICATION',
    NOTIFICATION_BILL_EXTRACT = 'NOTIFICATION_BILL_EXTRACT',
    NOTIFICATION_CUSTOMER_EXTRACT = 'NOTIFICATION_CUSTOMER_EXTRACT',
    GIRO_INTEREST = 'GIRO_INTEREST',
    CSAT_SURVEY = 'CSAT_SURVEY',
    SP_FORM = 'SP_FORM',
    SP_FORM_FOR_SP_GROUP = 'SP_FORM_FOR_SP_GROUP',
    DEPOSIT_RECEIVED = 'DEPOSIT_RECEIVED',
    CUSTOMER_DOCUMENT_HANDOVER = 'CUSTOMER_DOCUMENT_HANDOVER',
    CUSTOMER_CREATED = 'CUSTOMER_CREATED',
    DDA_SETUP_LINK = 'DDA_SETUP_LINK',
    RTO_PROPOSAL_OFFER = 'RTO_PROPOSAL_OFFER',
    TILE_RTO_PROPOSAL_OFFER = 'TILE_RTO_PROPOSAL_OFFER',
    MONTHLY_INVOICE = 'MONTHLY_INVOICE',
    CUSTOMER_STATUS_INVESTOR_APPROVAL_PENDING = 'CUSTOMER_STATUS_INVESTOR_APPROVAL_PENDING',
    CUSTOMER_STATUS_CONTRACT_SENT = 'CUSTOMER_STATUS_CONTRACT_SENT',
    CUSTOMER_STATUS_PAYMENT_PENDING = 'CUSTOMER_STATUS_PAYMENT_PENDING',
    CUSTOMER_STATUS_INSTALLATION_PENDING = 'CUSTOMER_STATUS_INSTALLATION_PENDING',
    CUSTOMER_STATUS_INSTALLATION_COMPLETE = 'CUSTOMER_STATUS_INSTALLATION_COMPLETE',
    CUSTOMER_STATUS_SYSTEM_COMMISSIONED = 'CUSTOMER_STATUS_SYSTEM_COMMISSIONED'
}

export type SetProfileMutationVariables = {
    country_code: COUNTRY_CODE;
    input: ProfileInput;
};

export type SetProfileMutation = {
    setProfile: {
        __typename: 'Profile';
        id: string;
        email: string;
        name: string;
        phone?: string | null;
        contact_method?: Array<CONTACT_METHOD> | null;
        family_name: string;
        company?: string | null;
        group: Array<string>;
        created: string;
    };
};

export type SetLeadMutationVariables = {
    country_code: COUNTRY_CODE;
    input: SetLeadInput;
};

export type SetLeadMutation = {
    setLead: {
        __typename: 'GroupLead';
        id: string;
        address: string;
        name: string;
        family_name?: string | null;
        email: string;
        phone?: string | null;
        company?: string | null;
        type: BUILDING_TYPE;
        roof_type: ROOF_MATERIAL;
        retailer?: string | null;
        utilities: number;
        meter_box?: METER_BOX | null;
        timeframe: number;
        installation_size: number;
        created: string;
        updated?: string | null;
        prospect_id?: string | null;
        src: number;
        validated: LEAD_VALIDATION;
        engagement?: LEAD_ENGAGEMENT | null;
        postal_code?: string | null;
        hubspot?: {
            __typename: 'Hubspot';
            contact_id?: string | null;
            deal_id?: string | null;
            error?: ERROR | null;
        } | null;
        product: PRODUCT;
        tags?: Array<LABELS> | null;
        trengo?: {
            __typename: 'Trengo';
            tickets: Array<{
                __typename: 'Ticket';
                id?: number | null;
                contact?: number | null;
                profile?: number | null;
                directory: string;
                channel: CHANNELS;
                type: CONTACT_TYPE;
                template?: TEMPLATE | null;
                error?: ERROR | null;
                params?: Array<{
                    __typename: 'WhatsappParam';
                    key: string;
                    value: string;
                }> | null;
            }>;
            ticket_id: Array<number | null>;
        } | null;
        sendgrid?: {
            __typename: 'Sendgrid';
            template?: TEMPLATE | null;
        } | null;
        lat?: number | null;
        lng?: number | null;
        polygon?: Array<{
            __typename: 'Polygon';
            lng: number;
            lat: number;
        }> | null;
        term?: TENDER_SELECTION | null;
        waitlist?: boolean | null;
        city?: string | null;
        state?: string | null;
    };
};

export type SetEpcMutationVariables = {
    country_code: COUNTRY_CODE;
    input: setEpcInput;
};

export type SetEpcMutation = {
    setEpc: Array<{
        __typename: 'EpcEntity';
        label: string;
        value: string;
        stats: Array<number>;
        total: number;
    } | null>;
};

export type DeleteEpcMutationVariables = {
    country_code: COUNTRY_CODE;
    input: setEpcInput;
};

export type DeleteEpcMutation = {
    deleteEpc: Array<{
        __typename: 'EpcEntity';
        label: string;
        value: string;
        stats: Array<number>;
        total: number;
    } | null>;
};

export type SetProspectEpcMutationVariables = {
    country_code: COUNTRY_CODE;
    id: string;
    input: Array<EpcInput>;
};

export type SetProspectEpcMutation = {
    setProspectEpc: Array<{
        __typename: 'Epc';
        company: {
            __typename: 'Company';
            value: string;
            label: string;
        };
        index: number;
        status: EPC_STATUS;
        capacity?: number | null;
        cost?: number | null;
        module?: string | null;
        panel_number?: number | null;
        inverter?: string | null;
        inverter_number?: number | null;
        scaffolding?: SCAFFOLDING | null;
        generated?: number | null;
        docs?: Array<{
            __typename: 'Doc';
            name: string;
            type: string;
            link?: string | null;
            category?: DOC_CATEGORY | null;
            deleted?: boolean | null;
            updated_at?: string | null;
        }> | null;
        comment?: string | null;
        remark?: string | null;
        created: string;
        updated: string;
        delivery: string;
        reports?: Array<{
            __typename: 'Report';
            date: string;
            text: string;
        }> | null;
    }>;
};

export type DeleteProspectEpcMutationVariables = {
    country_code: COUNTRY_CODE;
    id: string;
    index: number;
};

export type DeleteProspectEpcMutation = {
    deleteProspectEpc: Array<{
        __typename: 'Epc';
        company: {
            __typename: 'Company';
            value: string;
            label: string;
        };
        index: number;
        status: EPC_STATUS;
        capacity?: number | null;
        cost?: number | null;
        module?: string | null;
        panel_number?: number | null;
        inverter?: string | null;
        inverter_number?: number | null;
        scaffolding?: SCAFFOLDING | null;
        generated?: number | null;
        docs?: Array<{
            __typename: 'Doc';
            name: string;
            type: string;
            link?: string | null;
            category?: DOC_CATEGORY | null;
            deleted?: boolean | null;
            updated_at?: string | null;
        }> | null;
        comment?: string | null;
        remark?: string | null;
        created: string;
        updated: string;
        delivery: string;
        reports?: Array<{
            __typename: 'Report';
            date: string;
            text: string;
        }> | null;
    }>;
};

export type ResetUserPasswordMutationVariables = {
    name: string;
    email: string;
};

export type ResetUserPasswordMutation = {
    resetUserPassword: string;
};

export type SetBillsMutationVariables = {
    country_code: COUNTRY_CODE;
    bills: Array<BillInput>;
    keys: Array<number>;
};

export type SetBillsMutation = {
    setBills: {
        __typename: 'ListBills';
        bills: Array<{
            __typename: 'Bills';
            customer_id?: string | null;
            pk: string;
            invoice_id: string;
            invoice_date?: string | null;
            send_date?: string | null;
            due_date?: string | null;
            period_start?: string | null;
            period_end?: string | null;
            paid?: string | null;
            raw?: number | null;
            deposit?: number | null;
            total?: number | null;
            GST?: number | null;
            status: BILL_STATUS;
            type?: BILL_TYPE | null;
            rebate?: number | null;
            name?: string | null;
            family_name?: string | null;
            address?: string | null;
            zip?: string | null;
            capacity?: number | null;
            investor?: INVESTOR | null;
            installation_date?: string | null;
            commissioning_date?: string | null;
            billing_start?: string | null;
            contract_end?: string | null;
            term?: TENDER_SELECTION | null;
            billing_currency?: string | null;
            method?: PAYMENT_METHOD | null;
            bank?: string | null;
            bank_account?: string | null;
            bank_owner?: string | null;
            remark?: string | null;
            items?: Array<{
                __typename: 'BillItem';
                description: string;
                amount: number;
            }> | null;
            counter?: number | null;
            country_code?: COUNTRY_CODE | null;
            epc?: string | null;
        }>;
        total?: number | null;
        keys?: Array<number> | null;
    };
};

export type ExportDataMutationVariables = {
    country_code: COUNTRY_CODE;
    startDate: string;
    endDate: string;
    type: EXPORT;
};

export type ExportDataMutation = {
    exportData: string;
};

export type ReceiveIntegrationMutationVariables = {
    country_code: COUNTRY_CODE;
    input: IntegrationInput;
};

export type ReceiveIntegrationMutation = {
    receiveIntegration: {
        __typename: 'Integration';
        id: string;
        type: INTEGRATION_TYPE;
        keys?: Array<string> | null;
        error?: ERROR | null;
        country_code: COUNTRY_CODE;
    };
};

export type UpdateVendeeMutationVariables = {
    country_code: COUNTRY_CODE;
    input: VendeeInput;
};

export type UpdateVendeeMutation = {
    updateVendee: {
        __typename: 'Vendee';
        country_code: COUNTRY_CODE;
        id: string;
        src?: SRC | null;
        ids?: {
            __typename: 'Ids';
            lead?: string | null;
            prospect?: string | null;
            customer?: string | null;
            internal?: string | null;
        } | null;
        product: PRODUCT;
        owner?: string | null;
        address: string;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        directory?: {
            __typename: 'Directory';
            contacts: Array<{
                __typename: 'Contact';
                id: string;
                name: string;
                family_name: string;
                company?: string | null;
                email?: string | null;
                phone?: string | null;
                method?: Array<CONTACT_METHOD> | null;
                type: CONTACT_TYPE;
            }>;
        } | null;
        tags?: Array<LABELS> | null;
        simulation?: {
            __typename: 'Simulation';
            timeframe?: number | null;
            ip?: {
                __typename: 'Ip';
                source?: string | null;
                country?: string | null;
            } | null;
            src?: number | null;
            installation: {
                __typename: 'Installation';
                roi: number;
                cost: {
                    __typename: 'HighLow';
                    high: number;
                    low: number;
                };
                generated: number;
                size: number;
                lease: string;
            };
            savings: {
                __typename: 'Savings';
                utilities: {
                    __typename: 'HighLow';
                    high: number;
                    low: number;
                };
                ppa: {
                    __typename: 'HighLow';
                    high: number;
                    low: number;
                };
            };
            impact: {
                __typename: 'Impact';
                cars: number;
                trees: number;
                co2: number;
            };
            rtoOptions?: Array<{
                __typename: 'RtoOption';
                cost: number;
                payment: number;
            }> | null;
        } | null;
        site?: {
            __typename: 'Site';
            address_components?: Array<{
                __typename: 'AddressComponent';
                long_name: string;
                short_name: string;
                types: Array<string | null>;
            }> | null;
            type: BUILDING_TYPE;
            storeys?: number | null;
            height?: number | null;
            eui?: number | null;
            energy?: ENERGY | null;
            consumption: number;
            location: Array<number>;
            osm_id?: number | null;
            material: ROOF_MATERIAL;
            polygon: Array<{
                __typename: 'Polygon';
                lng: number;
                lat: number;
            }>;
            area: number;
            irradiation: number;
            utilities: {
                __typename: 'Utilities';
                input?: number | null;
                calculated?: number | null;
            };
            retailer?: RETAILER | null;
            daytime_consumption: number;
            daytime_consumption_rate: CONSUMPTION;
            meter_box?: METER_BOX | null;
        } | null;
        proffer?: {
            __typename: 'Proffer';
            comment?: string | null;
            sub_switchboard?: SUB_SWITCHBOARD | null;
            meter_box?: METER_BOX | null;
            occupancy?: OCCUPANCY | null;
            expected_date?: string | null;
            offers?: Array<TENDER_SELECTION> | null;
            epc?: Array<{
                __typename: 'Epc';
                company: {
                    __typename: 'Company';
                    value: string;
                    label: string;
                };
                index: number;
                status: EPC_STATUS;
                capacity?: number | null;
                cost?: number | null;
                module?: string | null;
                panel_number?: number | null;
                inverter?: string | null;
                inverter_number?: number | null;
                scaffolding?: SCAFFOLDING | null;
                generated?: number | null;
                docs?: Array<{
                    __typename: 'Doc';
                    name: string;
                    type: string;
                    link?: string | null;
                    category?: DOC_CATEGORY | null;
                    deleted?: boolean | null;
                    updated_at?: string | null;
                }> | null;
                comment?: string | null;
                remark?: string | null;
                created: string;
                updated: string;
                delivery: string;
                reports?: Array<{
                    __typename: 'Report';
                    date: string;
                    text: string;
                }> | null;
            }> | null;
            tender?: {
                __typename: 'Tender';
                selection?: TENDER_SELECTION | null;
                url?: string | null;
                link?: string | null;
                summary: {
                    __typename: 'TenderSummary';
                    size: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    generated: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    cost?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    savings?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    roi?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    monthly_savings?: number | null;
                };
                options: Array<{
                    __typename: 'TenderOptions';
                    cost?: number | null;
                    payment?: number | null;
                    maintenance?: number | null;
                    savings: number;
                }>;
                type: TENDER_TYPE;
            } | null;
            advisory_call_date?: string | null;
            advisory_call_time?: string | null;
        } | null;
        integrations?: {
            __typename: 'Integrations';
            trengo?: {
                __typename: 'Trengo';
                tickets: Array<{
                    __typename: 'Ticket';
                    id?: number | null;
                    contact?: number | null;
                    profile?: number | null;
                    directory: string;
                    channel: CHANNELS;
                    type: CONTACT_TYPE;
                    template?: TEMPLATE | null;
                    error?: ERROR | null;
                    params?: Array<{
                        __typename: 'WhatsappParam';
                        key: string;
                        value: string;
                    }> | null;
                }>;
                ticket_id: Array<number | null>;
            } | null;
            hubspot?: {
                __typename: 'Hubspot';
                contact_id?: string | null;
                deal_id?: string | null;
                error?: ERROR | null;
            } | null;
            sendgrid?: {
                __typename: 'Sendgrid';
                template?: TEMPLATE | null;
            } | null;
        } | null;
        status?: {
            __typename: 'ProspectStatus';
            value: PROSPECT_STATUS;
            date: string;
        } | null;
        validated?: LEAD_VALIDATION | null;
        engagement?: LEAD_ENGAGEMENT | null;
        docs?: Array<{
            __typename: 'Doc';
            name: string;
            type: string;
            link?: string | null;
            category?: DOC_CATEGORY | null;
            deleted?: boolean | null;
            updated_at?: string | null;
        }> | null;
        tally?: {
            __typename: 'Tally';
            installation?: string | null;
            commissioning?: string | null;
            deposit?: number | null;
            lease?: number | null;
            size?: number | null;
            term?: TENDER_SELECTION | null;
            investor?: INVESTOR | null;
            start?: string | null;
            end?: string | null;
            contract_end?: string | null;
            rebate?: number | null;
            payment?: {
                __typename: 'Payment';
                method?: PAYMENT_METHOD | null;
                bank?: BANK | null;
                bank_account?: string | null;
                bank_owner?: string | null;
            } | null;
            bills?: Array<{
                __typename: 'Bill';
                id: string;
                type: BILL_TYPE;
                status: BILL_STATUS;
                raw: number;
                total: number;
                GST: number;
                taxe: number;
                send_date: string;
                invoice_date: string;
                due_date: string;
                period_start?: string | null;
                period_end?: string | null;
                paid?: string | null;
                rebate?: number | null;
                method?: PAYMENT_METHOD | null;
                remark?: string | null;
                items?: Array<{
                    __typename: 'BillItem';
                    description: string;
                    amount: number;
                }> | null;
                counter?: number | null;
            }> | null;
            currency?: string | null;
            status?: CUSTOMER_STATUS | null;
            epc?: {
                __typename: 'Company';
                value: string;
                label: string;
            } | null;
            price?: number | null;
            capex?: number | null;
            installation_start?: string | null;
            site_visit?: string | null;
            site_visit_type?: SITE_VISIT_TYPE | null;
            retailer?: RETAILER | null;
            contract?: FORM_STATUS | null;
            whatsapp?: boolean | null;
            ami?: AMI | null;
            sp?: FORM_STATUS | null;
            sp_no?: string | null;
            giro?: GIRO_STATUS | null;
            inverter?: boolean | null;
            inverter_type?: Array<string> | null;
            inverter_number?: number | null;
            panel?: Array<string> | null;
            panel_number?: number | null;
            tierPayment?: Array<{
                __typename: 'PaymentTranche';
                payment?: number | null;
                paid: boolean;
                issued: boolean;
            }> | null;
            buydown?: string | null;
            invoice_items?: Array<{
                __typename: 'BillItem';
                description: string;
                amount: number;
            }> | null;
            e_giro?: {
                __typename: 'EGiro';
                status?: E_GIRO_STATUS | null;
            } | null;
            curlec?: {
                __typename: 'Curlec';
                status?: CURLEC_STATUS | null;
            } | null;
            nem_application_status?: NEM_APPLICATION_STATUS | null;
            epc_added_to_whatsapp?: boolean | null;
            tnb_stamp_fee_status?: TNB_STAMP_FEE_STATUS | null;
            installation_status?: INSTALLATION_STATUS | null;
            tnb_meter_change_date?: string | null;
            system_turn_on_date?: string | null;
            ami_meter_change_date?: string | null;
            installation_scheduled?: boolean | null;
            top_date?: string | null;
            upload_to_epc?: boolean | null;
        } | null;
        referral?: {
            __typename: 'Referral';
            name?: string | null;
        } | null;
        stages?: Array<{
            __typename: 'Stage';
            status: string;
            start: string;
            end?: string | null;
            duration?: number | null;
            category?: STAGE_CATEGORIES | null;
            id?: string | null;
        }> | null;
        created: {
            __typename: 'Created';
            vendee: string;
            lead?: string | null;
            prospect?: string | null;
            customer?: string | null;
        };
        updated?: string | null;
        comment?: string | null;
        cognito_user_id?: string | null;
        utm?: {
            __typename: 'Utm';
            source?: string | null;
            medium?: string | null;
            campaign?: string | null;
            term?: string | null;
            content?: string | null;
        } | null;
    };
};

export type AddLogMutationVariables = {
    country_code: COUNTRY_CODE;
    vendee_id: string;
    input: LogInput;
    send?: boolean | null;
};

export type AddLogMutation = {
    addLog: {
        __typename: 'Log';
        id: string;
        author?: {
            __typename: 'Author';
            username?: string | null;
            src: SRC;
        } | null;
        created?: string | null;
        changes?: Array<{
            __typename: 'Change';
            op: OP;
            path: string;
            value?: string | null;
        } | null> | null;
        content?: string | null;
        visit?: {
            __typename: 'Visit';
            pitch: string;
            access: string;
            installation: string;
            inverter: string;
            cabling: string;
            timeline: string;
            other: string;
            docs: Array<{
                __typename: 'Doc';
                name: string;
                type: string;
                link?: string | null;
                category?: DOC_CATEGORY | null;
                deleted?: boolean | null;
                updated_at?: string | null;
            }>;
        } | null;
        category: LOG_CATEGORIES;
        stage?: {
            __typename: 'Stages';
            type: PROSPECT_STATUS;
            product?: PRODUCT | null;
            comment?: string | null;
            date?: string | null;
            url?: string | null;
            select?: Array<string> | null;
            price?: number | null;
            commission?: number | null;
            size?: number | null;
            retailer?: RETAILER | null;
            investor?: INVESTOR | null;
            term?: TENDER_SELECTION | null;
            down_payment?: number | null;
            lease?: number | null;
            installation_date?: string | null;
            capex?: number | null;
            fee?: {
                __typename: 'Fee';
                start?: number | null;
                end?: number | null;
                management?: number | null;
            } | null;
            irr?: number | null;
            docs?: Array<{
                __typename: 'Doc';
                name: string;
                type: string;
                link?: string | null;
                category?: DOC_CATEGORY | null;
                deleted?: boolean | null;
                updated_at?: string | null;
            }> | null;
            tender?: {
                __typename: 'Tender';
                selection?: TENDER_SELECTION | null;
                url?: string | null;
                link?: string | null;
                summary: {
                    __typename: 'TenderSummary';
                    size: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    generated: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    cost?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    savings?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    roi?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    monthly_savings?: number | null;
                };
                options: Array<{
                    __typename: 'TenderOptions';
                    cost?: number | null;
                    payment?: number | null;
                    maintenance?: number | null;
                    savings: number;
                }>;
                type: TENDER_TYPE;
            } | null;
            epc?: {
                __typename: 'Company';
                value: string;
                label: string;
            } | null;
            advisory_call_date?: string | null;
            advisory_call_time?: string | null;
        } | null;
        favourite?: boolean | null;
        editor_content?: {
            __typename: 'ContentState';
            blocks?: Array<{
                __typename: 'Block';
                key?: string | null;
                text?: string | null;
                type?: string | null;
                depth?: number | null;
                inlineStyleRanges?: Array<{
                    __typename: 'InlineStyleRange';
                    offset?: number | null;
                    length?: number | null;
                    style?: string | null;
                } | null> | null;
                entityRanges?: Array<{
                    __typename: 'EntityRange';
                    offset?: number | null;
                    length?: number | null;
                    key?: number | null;
                } | null> | null;
                data?: {
                    __typename: 'Data';
                    id?: string | null;
                } | null;
            } | null> | null;
            entityMap?: Array<{
                __typename: 'Entity';
                type?: string | null;
                mutability?: string | null;
                data?: {
                    __typename: 'EntityData';
                    mention?: {
                        __typename: 'MentionData';
                        value?: string | null;
                        name?: string | null;
                        label?: string | null;
                        id?: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null;
    };
};

export type DeleteProfileMutationVariables = {
    country_code: COUNTRY_CODE;
    id: string;
};

export type DeleteProfileMutation = {
    deleteProfile: string;
};

export type SetStatusMutationVariables = {
    country_code: COUNTRY_CODE;
    input: StatusInput;
};

export type SetStatusMutation = {
    setStatus?: {
        __typename: 'SetStatusReturn';
        log: {
            __typename: 'Log';
            id: string;
            author?: {
                __typename: 'Author';
                username?: string | null;
                src: SRC;
            } | null;
            created?: string | null;
            changes?: Array<{
                __typename: 'Change';
                op: OP;
                path: string;
                value?: string | null;
            } | null> | null;
            content?: string | null;
            visit?: {
                __typename: 'Visit';
                pitch: string;
                access: string;
                installation: string;
                inverter: string;
                cabling: string;
                timeline: string;
                other: string;
                docs: Array<{
                    __typename: 'Doc';
                    name: string;
                    type: string;
                    link?: string | null;
                    category?: DOC_CATEGORY | null;
                    deleted?: boolean | null;
                    updated_at?: string | null;
                }>;
            } | null;
            category: LOG_CATEGORIES;
            stage?: {
                __typename: 'Stages';
                type: PROSPECT_STATUS;
                product?: PRODUCT | null;
                comment?: string | null;
                date?: string | null;
                url?: string | null;
                select?: Array<string> | null;
                price?: number | null;
                commission?: number | null;
                size?: number | null;
                retailer?: RETAILER | null;
                investor?: INVESTOR | null;
                term?: TENDER_SELECTION | null;
                down_payment?: number | null;
                lease?: number | null;
                installation_date?: string | null;
                capex?: number | null;
                fee?: {
                    __typename: 'Fee';
                    start?: number | null;
                    end?: number | null;
                    management?: number | null;
                } | null;
                irr?: number | null;
                docs?: Array<{
                    __typename: 'Doc';
                    name: string;
                    type: string;
                    link?: string | null;
                    category?: DOC_CATEGORY | null;
                    deleted?: boolean | null;
                    updated_at?: string | null;
                }> | null;
                tender?: {
                    __typename: 'Tender';
                    selection?: TENDER_SELECTION | null;
                    url?: string | null;
                    link?: string | null;
                    summary: {
                        __typename: 'TenderSummary';
                        size: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        };
                        generated: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        };
                        cost?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        savings?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        roi?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        monthly_savings?: number | null;
                    };
                    options: Array<{
                        __typename: 'TenderOptions';
                        cost?: number | null;
                        payment?: number | null;
                        maintenance?: number | null;
                        savings: number;
                    }>;
                    type: TENDER_TYPE;
                } | null;
                epc?: {
                    __typename: 'Company';
                    value: string;
                    label: string;
                } | null;
                advisory_call_date?: string | null;
                advisory_call_time?: string | null;
            } | null;
            favourite?: boolean | null;
            editor_content?: {
                __typename: 'ContentState';
                blocks?: Array<{
                    __typename: 'Block';
                    key?: string | null;
                    text?: string | null;
                    type?: string | null;
                    depth?: number | null;
                    inlineStyleRanges?: Array<{
                        __typename: 'InlineStyleRange';
                        offset?: number | null;
                        length?: number | null;
                        style?: string | null;
                    } | null> | null;
                    entityRanges?: Array<{
                        __typename: 'EntityRange';
                        offset?: number | null;
                        length?: number | null;
                        key?: number | null;
                    } | null> | null;
                    data?: {
                        __typename: 'Data';
                        id?: string | null;
                    } | null;
                } | null> | null;
                entityMap?: Array<{
                    __typename: 'Entity';
                    type?: string | null;
                    mutability?: string | null;
                    data?: {
                        __typename: 'EntityData';
                        mention?: {
                            __typename: 'MentionData';
                            value?: string | null;
                            name?: string | null;
                            label?: string | null;
                            id?: string | null;
                        } | null;
                    } | null;
                } | null> | null;
            } | null;
        };
        stages?: Array<{
            __typename: 'Stage';
            status: string;
            start: string;
            end?: string | null;
            duration?: number | null;
            category?: STAGE_CATEGORIES | null;
            id?: string | null;
        }> | null;
        status: {
            __typename: 'ProspectStatus';
            value: PROSPECT_STATUS;
            date: string;
        };
    } | null;
};

export type SetMilestoneMutationVariables = {
    country_code: COUNTRY_CODE;
    input: SetMilestoneInput;
};

export type SetMilestoneMutation = {
    setMilestone: {
        __typename: 'Vendee';
        country_code: COUNTRY_CODE;
        id: string;
        src?: SRC | null;
        ids?: {
            __typename: 'Ids';
            lead?: string | null;
            prospect?: string | null;
            customer?: string | null;
            internal?: string | null;
        } | null;
        product: PRODUCT;
        owner?: string | null;
        address: string;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        directory?: {
            __typename: 'Directory';
            contacts: Array<{
                __typename: 'Contact';
                id: string;
                name: string;
                family_name: string;
                company?: string | null;
                email?: string | null;
                phone?: string | null;
                method?: Array<CONTACT_METHOD> | null;
                type: CONTACT_TYPE;
            }>;
        } | null;
        tags?: Array<LABELS> | null;
        simulation?: {
            __typename: 'Simulation';
            timeframe?: number | null;
            ip?: {
                __typename: 'Ip';
                source?: string | null;
                country?: string | null;
            } | null;
            src?: number | null;
            installation: {
                __typename: 'Installation';
                roi: number;
                cost: {
                    __typename: 'HighLow';
                    high: number;
                    low: number;
                };
                generated: number;
                size: number;
                lease: string;
            };
            savings: {
                __typename: 'Savings';
                utilities: {
                    __typename: 'HighLow';
                    high: number;
                    low: number;
                };
                ppa: {
                    __typename: 'HighLow';
                    high: number;
                    low: number;
                };
            };
            impact: {
                __typename: 'Impact';
                cars: number;
                trees: number;
                co2: number;
            };
            rtoOptions?: Array<{
                __typename: 'RtoOption';
                cost: number;
                payment: number;
            }> | null;
        } | null;
        site?: {
            __typename: 'Site';
            address_components?: Array<{
                __typename: 'AddressComponent';
                long_name: string;
                short_name: string;
                types: Array<string | null>;
            }> | null;
            type: BUILDING_TYPE;
            storeys?: number | null;
            height?: number | null;
            eui?: number | null;
            energy?: ENERGY | null;
            consumption: number;
            location: Array<number>;
            osm_id?: number | null;
            material: ROOF_MATERIAL;
            polygon: Array<{
                __typename: 'Polygon';
                lng: number;
                lat: number;
            }>;
            area: number;
            irradiation: number;
            utilities: {
                __typename: 'Utilities';
                input?: number | null;
                calculated?: number | null;
            };
            retailer?: RETAILER | null;
            daytime_consumption: number;
            daytime_consumption_rate: CONSUMPTION;
            meter_box?: METER_BOX | null;
        } | null;
        proffer?: {
            __typename: 'Proffer';
            comment?: string | null;
            sub_switchboard?: SUB_SWITCHBOARD | null;
            meter_box?: METER_BOX | null;
            occupancy?: OCCUPANCY | null;
            expected_date?: string | null;
            offers?: Array<TENDER_SELECTION> | null;
            epc?: Array<{
                __typename: 'Epc';
                company: {
                    __typename: 'Company';
                    value: string;
                    label: string;
                };
                index: number;
                status: EPC_STATUS;
                capacity?: number | null;
                cost?: number | null;
                module?: string | null;
                panel_number?: number | null;
                inverter?: string | null;
                inverter_number?: number | null;
                scaffolding?: SCAFFOLDING | null;
                generated?: number | null;
                docs?: Array<{
                    __typename: 'Doc';
                    name: string;
                    type: string;
                    link?: string | null;
                    category?: DOC_CATEGORY | null;
                    deleted?: boolean | null;
                    updated_at?: string | null;
                }> | null;
                comment?: string | null;
                remark?: string | null;
                created: string;
                updated: string;
                delivery: string;
                reports?: Array<{
                    __typename: 'Report';
                    date: string;
                    text: string;
                }> | null;
            }> | null;
            tender?: {
                __typename: 'Tender';
                selection?: TENDER_SELECTION | null;
                url?: string | null;
                link?: string | null;
                summary: {
                    __typename: 'TenderSummary';
                    size: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    generated: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    cost?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    savings?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    roi?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    monthly_savings?: number | null;
                };
                options: Array<{
                    __typename: 'TenderOptions';
                    cost?: number | null;
                    payment?: number | null;
                    maintenance?: number | null;
                    savings: number;
                }>;
                type: TENDER_TYPE;
            } | null;
            advisory_call_date?: string | null;
            advisory_call_time?: string | null;
        } | null;
        integrations?: {
            __typename: 'Integrations';
            trengo?: {
                __typename: 'Trengo';
                tickets: Array<{
                    __typename: 'Ticket';
                    id?: number | null;
                    contact?: number | null;
                    profile?: number | null;
                    directory: string;
                    channel: CHANNELS;
                    type: CONTACT_TYPE;
                    template?: TEMPLATE | null;
                    error?: ERROR | null;
                    params?: Array<{
                        __typename: 'WhatsappParam';
                        key: string;
                        value: string;
                    }> | null;
                }>;
                ticket_id: Array<number | null>;
            } | null;
            hubspot?: {
                __typename: 'Hubspot';
                contact_id?: string | null;
                deal_id?: string | null;
                error?: ERROR | null;
            } | null;
            sendgrid?: {
                __typename: 'Sendgrid';
                template?: TEMPLATE | null;
            } | null;
        } | null;
        status?: {
            __typename: 'ProspectStatus';
            value: PROSPECT_STATUS;
            date: string;
        } | null;
        validated?: LEAD_VALIDATION | null;
        engagement?: LEAD_ENGAGEMENT | null;
        docs?: Array<{
            __typename: 'Doc';
            name: string;
            type: string;
            link?: string | null;
            category?: DOC_CATEGORY | null;
            deleted?: boolean | null;
            updated_at?: string | null;
        }> | null;
        tally?: {
            __typename: 'Tally';
            installation?: string | null;
            commissioning?: string | null;
            deposit?: number | null;
            lease?: number | null;
            size?: number | null;
            term?: TENDER_SELECTION | null;
            investor?: INVESTOR | null;
            start?: string | null;
            end?: string | null;
            contract_end?: string | null;
            rebate?: number | null;
            payment?: {
                __typename: 'Payment';
                method?: PAYMENT_METHOD | null;
                bank?: BANK | null;
                bank_account?: string | null;
                bank_owner?: string | null;
            } | null;
            bills?: Array<{
                __typename: 'Bill';
                id: string;
                type: BILL_TYPE;
                status: BILL_STATUS;
                raw: number;
                total: number;
                GST: number;
                taxe: number;
                send_date: string;
                invoice_date: string;
                due_date: string;
                period_start?: string | null;
                period_end?: string | null;
                paid?: string | null;
                rebate?: number | null;
                method?: PAYMENT_METHOD | null;
                remark?: string | null;
                items?: Array<{
                    __typename: 'BillItem';
                    description: string;
                    amount: number;
                }> | null;
                counter?: number | null;
            }> | null;
            currency?: string | null;
            status?: CUSTOMER_STATUS | null;
            epc?: {
                __typename: 'Company';
                value: string;
                label: string;
            } | null;
            price?: number | null;
            capex?: number | null;
            installation_start?: string | null;
            site_visit?: string | null;
            site_visit_type?: SITE_VISIT_TYPE | null;
            retailer?: RETAILER | null;
            contract?: FORM_STATUS | null;
            whatsapp?: boolean | null;
            ami?: AMI | null;
            sp?: FORM_STATUS | null;
            sp_no?: string | null;
            giro?: GIRO_STATUS | null;
            inverter?: boolean | null;
            inverter_type?: Array<string> | null;
            inverter_number?: number | null;
            panel?: Array<string> | null;
            panel_number?: number | null;
            tierPayment?: Array<{
                __typename: 'PaymentTranche';
                payment?: number | null;
                paid: boolean;
                issued: boolean;
            }> | null;
            buydown?: string | null;
            invoice_items?: Array<{
                __typename: 'BillItem';
                description: string;
                amount: number;
            }> | null;
            e_giro?: {
                __typename: 'EGiro';
                status?: E_GIRO_STATUS | null;
            } | null;
            curlec?: {
                __typename: 'Curlec';
                status?: CURLEC_STATUS | null;
            } | null;
            nem_application_status?: NEM_APPLICATION_STATUS | null;
            epc_added_to_whatsapp?: boolean | null;
            tnb_stamp_fee_status?: TNB_STAMP_FEE_STATUS | null;
            installation_status?: INSTALLATION_STATUS | null;
            tnb_meter_change_date?: string | null;
            system_turn_on_date?: string | null;
            ami_meter_change_date?: string | null;
            installation_scheduled?: boolean | null;
            top_date?: string | null;
            upload_to_epc?: boolean | null;
        } | null;
        referral?: {
            __typename: 'Referral';
            name?: string | null;
        } | null;
        stages?: Array<{
            __typename: 'Stage';
            status: string;
            start: string;
            end?: string | null;
            duration?: number | null;
            category?: STAGE_CATEGORIES | null;
            id?: string | null;
        }> | null;
        created: {
            __typename: 'Created';
            vendee: string;
            lead?: string | null;
            prospect?: string | null;
            customer?: string | null;
        };
        updated?: string | null;
        comment?: string | null;
        cognito_user_id?: string | null;
        utm?: {
            __typename: 'Utm';
            source?: string | null;
            medium?: string | null;
            campaign?: string | null;
            term?: string | null;
            content?: string | null;
        } | null;
    };
};

export type SetFavouriteMutationVariables = {
    country_code: COUNTRY_CODE;
    id: string;
    favourite: boolean;
};

export type SetFavouriteMutation = {
    setFavourite: boolean;
};

export type CreateAccountMutationVariables = {
    country_code: COUNTRY_CODE;
    id: string;
};

export type CreateAccountMutation = {
    createAccount: string;
};

export type GetUsersQueryVariables = {
    country_code: COUNTRY_CODE;
    group?: Array<USER_GROUP> | null;
    search_term?: string | null;
    sort?: SORT | null;
    sort_field?: USER_SORT_FIELD | null;
    from: number;
    size: number;
};

export type GetUsersQuery = {
    getUsers: {
        __typename: 'ListUsers';
        total: number;
        users: Array<{
            __typename: 'Profile';
            id: string;
            email: string;
            name: string;
            phone?: string | null;
            contact_method?: Array<CONTACT_METHOD> | null;
            family_name: string;
            company?: string | null;
            group: Array<string>;
            created: string;
        }>;
    };
};

export type GetProfileQueryVariables = {
    country_code: COUNTRY_CODE;
    id: string;
};

export type GetProfileQuery = {
    getProfile?: {
        __typename: 'Profile';
        id: string;
        email: string;
        name: string;
        phone?: string | null;
        contact_method?: Array<CONTACT_METHOD> | null;
        family_name: string;
        company?: string | null;
        group: Array<string>;
        created: string;
    } | null;
};

export type ListGroupedLeadsQueryVariables = {
    country_code: COUNTRY_CODE;
    building_type?: Array<string> | null;
    validated?: Array<LEAD_VALIDATION> | null;
    engagement?: Array<LEAD_ENGAGEMENT> | null;
    search_term?: string | null;
    included?: Array<LABELS> | null;
    excluded?: Array<LABELS> | null;
    sort?: SORT | null;
    sort_field?: LEAD_SORT_FIELD | null;
    from: number;
    size: number;
};

export type ListGroupedLeadsQuery = {
    listGroupedLeads: {
        __typename: 'ListGroupLeads';
        total: number;
        groups: Array<{
            __typename: 'GroupLeads';
            total: number;
            leads: Array<{
                __typename: 'GroupLead';
                id: string;
                address: string;
                name: string;
                family_name?: string | null;
                email: string;
                phone?: string | null;
                company?: string | null;
                type: BUILDING_TYPE;
                roof_type: ROOF_MATERIAL;
                retailer?: string | null;
                utilities: number;
                meter_box?: METER_BOX | null;
                timeframe: number;
                installation_size: number;
                created: string;
                updated?: string | null;
                prospect_id?: string | null;
                src: number;
                validated: LEAD_VALIDATION;
                engagement?: LEAD_ENGAGEMENT | null;
                postal_code?: string | null;
                hubspot?: {
                    __typename: 'Hubspot';
                    contact_id?: string | null;
                    deal_id?: string | null;
                    error?: ERROR | null;
                } | null;
                product: PRODUCT;
                tags?: Array<LABELS> | null;
                trengo?: {
                    __typename: 'Trengo';
                    tickets: Array<{
                        __typename: 'Ticket';
                        id?: number | null;
                        contact?: number | null;
                        profile?: number | null;
                        directory: string;
                        channel: CHANNELS;
                        type: CONTACT_TYPE;
                        template?: TEMPLATE | null;
                        error?: ERROR | null;
                        params?: Array<{
                            __typename: 'WhatsappParam';
                            key: string;
                            value: string;
                        }> | null;
                    }>;
                    ticket_id: Array<number | null>;
                } | null;
                sendgrid?: {
                    __typename: 'Sendgrid';
                    template?: TEMPLATE | null;
                } | null;
                lat?: number | null;
                lng?: number | null;
                polygon?: Array<{
                    __typename: 'Polygon';
                    lng: number;
                    lat: number;
                }> | null;
                term?: TENDER_SELECTION | null;
                waitlist?: boolean | null;
                city?: string | null;
                state?: string | null;
            }>;
        }>;
    };
};

export type ListEpcQueryVariables = {
    country_code: COUNTRY_CODE;
};

export type ListEpcQuery = {
    listEpc: Array<{
        __typename: 'EpcEntity';
        label: string;
        value: string;
        stats: Array<number>;
        total: number;
    }>;
};

export type ListVendeesQueryVariables = {
    country_code: COUNTRY_CODE;
    sort?: SORT | null;
    sort_field?: PROSPECT_SORT_FIELD | null;
    from: number;
    size: number;
    owner?: Array<string> | null;
    status?: Array<PROSPECT_STATUS> | null;
    search_term?: string | null;
    epc?: Array<string> | null;
    epc_status?: Array<EPC_STATUS> | null;
    roof_material?: Array<ROOF_MATERIAL> | null;
    product?: Array<PRODUCT> | null;
};

export type ListVendeesQuery = {
    listVendees: {
        __typename: 'ListVendees';
        total: number;
        vendees: Array<{
            __typename: 'Vendee';
            country_code: COUNTRY_CODE;
            id: string;
            src?: SRC | null;
            ids?: {
                __typename: 'Ids';
                lead?: string | null;
                prospect?: string | null;
                customer?: string | null;
                internal?: string | null;
            } | null;
            product: PRODUCT;
            owner?: string | null;
            address: string;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
            directory?: {
                __typename: 'Directory';
                contacts: Array<{
                    __typename: 'Contact';
                    id: string;
                    name: string;
                    family_name: string;
                    company?: string | null;
                    email?: string | null;
                    phone?: string | null;
                    method?: Array<CONTACT_METHOD> | null;
                    type: CONTACT_TYPE;
                }>;
            } | null;
            tags?: Array<LABELS> | null;
            simulation?: {
                __typename: 'Simulation';
                timeframe?: number | null;
                ip?: {
                    __typename: 'Ip';
                    source?: string | null;
                    country?: string | null;
                } | null;
                src?: number | null;
                installation: {
                    __typename: 'Installation';
                    roi: number;
                    cost: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    generated: number;
                    size: number;
                    lease: string;
                };
                savings: {
                    __typename: 'Savings';
                    utilities: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    ppa: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                };
                impact: {
                    __typename: 'Impact';
                    cars: number;
                    trees: number;
                    co2: number;
                };
                rtoOptions?: Array<{
                    __typename: 'RtoOption';
                    cost: number;
                    payment: number;
                }> | null;
            } | null;
            site?: {
                __typename: 'Site';
                address_components?: Array<{
                    __typename: 'AddressComponent';
                    long_name: string;
                    short_name: string;
                    types: Array<string | null>;
                }> | null;
                type: BUILDING_TYPE;
                storeys?: number | null;
                height?: number | null;
                eui?: number | null;
                energy?: ENERGY | null;
                consumption: number;
                location: Array<number>;
                osm_id?: number | null;
                material: ROOF_MATERIAL;
                polygon: Array<{
                    __typename: 'Polygon';
                    lng: number;
                    lat: number;
                }>;
                area: number;
                irradiation: number;
                utilities: {
                    __typename: 'Utilities';
                    input?: number | null;
                    calculated?: number | null;
                };
                retailer?: RETAILER | null;
                daytime_consumption: number;
                daytime_consumption_rate: CONSUMPTION;
                meter_box?: METER_BOX | null;
            } | null;
            proffer?: {
                __typename: 'Proffer';
                comment?: string | null;
                sub_switchboard?: SUB_SWITCHBOARD | null;
                meter_box?: METER_BOX | null;
                occupancy?: OCCUPANCY | null;
                expected_date?: string | null;
                offers?: Array<TENDER_SELECTION> | null;
                epc?: Array<{
                    __typename: 'Epc';
                    company: {
                        __typename: 'Company';
                        value: string;
                        label: string;
                    };
                    index: number;
                    status: EPC_STATUS;
                    capacity?: number | null;
                    cost?: number | null;
                    module?: string | null;
                    panel_number?: number | null;
                    inverter?: string | null;
                    inverter_number?: number | null;
                    scaffolding?: SCAFFOLDING | null;
                    generated?: number | null;
                    docs?: Array<{
                        __typename: 'Doc';
                        name: string;
                        type: string;
                        link?: string | null;
                        category?: DOC_CATEGORY | null;
                        deleted?: boolean | null;
                        updated_at?: string | null;
                    }> | null;
                    comment?: string | null;
                    remark?: string | null;
                    created: string;
                    updated: string;
                    delivery: string;
                    reports?: Array<{
                        __typename: 'Report';
                        date: string;
                        text: string;
                    }> | null;
                }> | null;
                tender?: {
                    __typename: 'Tender';
                    selection?: TENDER_SELECTION | null;
                    url?: string | null;
                    link?: string | null;
                    summary: {
                        __typename: 'TenderSummary';
                        size: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        };
                        generated: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        };
                        cost?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        savings?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        roi?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        monthly_savings?: number | null;
                    };
                    options: Array<{
                        __typename: 'TenderOptions';
                        cost?: number | null;
                        payment?: number | null;
                        maintenance?: number | null;
                        savings: number;
                    }>;
                    type: TENDER_TYPE;
                } | null;
                advisory_call_date?: string | null;
                advisory_call_time?: string | null;
            } | null;
            integrations?: {
                __typename: 'Integrations';
                trengo?: {
                    __typename: 'Trengo';
                    tickets: Array<{
                        __typename: 'Ticket';
                        id?: number | null;
                        contact?: number | null;
                        profile?: number | null;
                        directory: string;
                        channel: CHANNELS;
                        type: CONTACT_TYPE;
                        template?: TEMPLATE | null;
                        error?: ERROR | null;
                        params?: Array<{
                            __typename: 'WhatsappParam';
                            key: string;
                            value: string;
                        }> | null;
                    }>;
                    ticket_id: Array<number | null>;
                } | null;
                hubspot?: {
                    __typename: 'Hubspot';
                    contact_id?: string | null;
                    deal_id?: string | null;
                    error?: ERROR | null;
                } | null;
                sendgrid?: {
                    __typename: 'Sendgrid';
                    template?: TEMPLATE | null;
                } | null;
            } | null;
            status?: {
                __typename: 'ProspectStatus';
                value: PROSPECT_STATUS;
                date: string;
            } | null;
            validated?: LEAD_VALIDATION | null;
            engagement?: LEAD_ENGAGEMENT | null;
            docs?: Array<{
                __typename: 'Doc';
                name: string;
                type: string;
                link?: string | null;
                category?: DOC_CATEGORY | null;
                deleted?: boolean | null;
                updated_at?: string | null;
            }> | null;
            tally?: {
                __typename: 'Tally';
                installation?: string | null;
                commissioning?: string | null;
                deposit?: number | null;
                lease?: number | null;
                size?: number | null;
                term?: TENDER_SELECTION | null;
                investor?: INVESTOR | null;
                start?: string | null;
                end?: string | null;
                contract_end?: string | null;
                rebate?: number | null;
                payment?: {
                    __typename: 'Payment';
                    method?: PAYMENT_METHOD | null;
                    bank?: BANK | null;
                    bank_account?: string | null;
                    bank_owner?: string | null;
                } | null;
                bills?: Array<{
                    __typename: 'Bill';
                    id: string;
                    type: BILL_TYPE;
                    status: BILL_STATUS;
                    raw: number;
                    total: number;
                    GST: number;
                    taxe: number;
                    send_date: string;
                    invoice_date: string;
                    due_date: string;
                    period_start?: string | null;
                    period_end?: string | null;
                    paid?: string | null;
                    rebate?: number | null;
                    method?: PAYMENT_METHOD | null;
                    remark?: string | null;
                    items?: Array<{
                        __typename: 'BillItem';
                        description: string;
                        amount: number;
                    }> | null;
                    counter?: number | null;
                }> | null;
                currency?: string | null;
                status?: CUSTOMER_STATUS | null;
                epc?: {
                    __typename: 'Company';
                    value: string;
                    label: string;
                } | null;
                price?: number | null;
                capex?: number | null;
                installation_start?: string | null;
                site_visit?: string | null;
                site_visit_type?: SITE_VISIT_TYPE | null;
                retailer?: RETAILER | null;
                contract?: FORM_STATUS | null;
                whatsapp?: boolean | null;
                ami?: AMI | null;
                sp?: FORM_STATUS | null;
                sp_no?: string | null;
                giro?: GIRO_STATUS | null;
                inverter?: boolean | null;
                inverter_type?: Array<string> | null;
                inverter_number?: number | null;
                panel?: Array<string> | null;
                panel_number?: number | null;
                tierPayment?: Array<{
                    __typename: 'PaymentTranche';
                    payment?: number | null;
                    paid: boolean;
                    issued: boolean;
                }> | null;
                buydown?: string | null;
                invoice_items?: Array<{
                    __typename: 'BillItem';
                    description: string;
                    amount: number;
                }> | null;
                e_giro?: {
                    __typename: 'EGiro';
                    status?: E_GIRO_STATUS | null;
                } | null;
                curlec?: {
                    __typename: 'Curlec';
                    status?: CURLEC_STATUS | null;
                } | null;
                nem_application_status?: NEM_APPLICATION_STATUS | null;
                epc_added_to_whatsapp?: boolean | null;
                tnb_stamp_fee_status?: TNB_STAMP_FEE_STATUS | null;
                installation_status?: INSTALLATION_STATUS | null;
                tnb_meter_change_date?: string | null;
                system_turn_on_date?: string | null;
                ami_meter_change_date?: string | null;
                installation_scheduled?: boolean | null;
                top_date?: string | null;
                upload_to_epc?: boolean | null;
            } | null;
            referral?: {
                __typename: 'Referral';
                name?: string | null;
            } | null;
            stages?: Array<{
                __typename: 'Stage';
                status: string;
                start: string;
                end?: string | null;
                duration?: number | null;
                category?: STAGE_CATEGORIES | null;
                id?: string | null;
            }> | null;
            created: {
                __typename: 'Created';
                vendee: string;
                lead?: string | null;
                prospect?: string | null;
                customer?: string | null;
            };
            updated?: string | null;
            comment?: string | null;
            cognito_user_id?: string | null;
            utm?: {
                __typename: 'Utm';
                source?: string | null;
                medium?: string | null;
                campaign?: string | null;
                term?: string | null;
                content?: string | null;
            } | null;
        }>;
    };
};

export type ListCustomersQueryVariables = {
    country_code: COUNTRY_CODE;
    order?: SORT | null;
    sort?: CUSTOMER_SORT_FIELD | null;
    from: number;
    size: number;
    search?: string | null;
    product?: Array<PRODUCT> | null;
};

export type ListCustomersQuery = {
    listCustomers: {
        __typename: 'ListCustomers';
        total: number;
        customers: Array<{
            __typename: 'Vendee';
            country_code: COUNTRY_CODE;
            id: string;
            src?: SRC | null;
            ids?: {
                __typename: 'Ids';
                lead?: string | null;
                prospect?: string | null;
                customer?: string | null;
                internal?: string | null;
            } | null;
            product: PRODUCT;
            owner?: string | null;
            address: string;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
            directory?: {
                __typename: 'Directory';
                contacts: Array<{
                    __typename: 'Contact';
                    id: string;
                    name: string;
                    family_name: string;
                    company?: string | null;
                    email?: string | null;
                    phone?: string | null;
                    method?: Array<CONTACT_METHOD> | null;
                    type: CONTACT_TYPE;
                }>;
            } | null;
            tags?: Array<LABELS> | null;
            simulation?: {
                __typename: 'Simulation';
                timeframe?: number | null;
                ip?: {
                    __typename: 'Ip';
                    source?: string | null;
                    country?: string | null;
                } | null;
                src?: number | null;
                installation: {
                    __typename: 'Installation';
                    roi: number;
                    cost: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    generated: number;
                    size: number;
                    lease: string;
                };
                savings: {
                    __typename: 'Savings';
                    utilities: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    ppa: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                };
                impact: {
                    __typename: 'Impact';
                    cars: number;
                    trees: number;
                    co2: number;
                };
                rtoOptions?: Array<{
                    __typename: 'RtoOption';
                    cost: number;
                    payment: number;
                }> | null;
            } | null;
            site?: {
                __typename: 'Site';
                address_components?: Array<{
                    __typename: 'AddressComponent';
                    long_name: string;
                    short_name: string;
                    types: Array<string | null>;
                }> | null;
                type: BUILDING_TYPE;
                storeys?: number | null;
                height?: number | null;
                eui?: number | null;
                energy?: ENERGY | null;
                consumption: number;
                location: Array<number>;
                osm_id?: number | null;
                material: ROOF_MATERIAL;
                polygon: Array<{
                    __typename: 'Polygon';
                    lng: number;
                    lat: number;
                }>;
                area: number;
                irradiation: number;
                utilities: {
                    __typename: 'Utilities';
                    input?: number | null;
                    calculated?: number | null;
                };
                retailer?: RETAILER | null;
                daytime_consumption: number;
                daytime_consumption_rate: CONSUMPTION;
                meter_box?: METER_BOX | null;
            } | null;
            proffer?: {
                __typename: 'Proffer';
                comment?: string | null;
                sub_switchboard?: SUB_SWITCHBOARD | null;
                meter_box?: METER_BOX | null;
                occupancy?: OCCUPANCY | null;
                expected_date?: string | null;
                offers?: Array<TENDER_SELECTION> | null;
                epc?: Array<{
                    __typename: 'Epc';
                    company: {
                        __typename: 'Company';
                        value: string;
                        label: string;
                    };
                    index: number;
                    status: EPC_STATUS;
                    capacity?: number | null;
                    cost?: number | null;
                    module?: string | null;
                    panel_number?: number | null;
                    inverter?: string | null;
                    inverter_number?: number | null;
                    scaffolding?: SCAFFOLDING | null;
                    generated?: number | null;
                    docs?: Array<{
                        __typename: 'Doc';
                        name: string;
                        type: string;
                        link?: string | null;
                        category?: DOC_CATEGORY | null;
                        deleted?: boolean | null;
                        updated_at?: string | null;
                    }> | null;
                    comment?: string | null;
                    remark?: string | null;
                    created: string;
                    updated: string;
                    delivery: string;
                    reports?: Array<{
                        __typename: 'Report';
                        date: string;
                        text: string;
                    }> | null;
                }> | null;
                tender?: {
                    __typename: 'Tender';
                    selection?: TENDER_SELECTION | null;
                    url?: string | null;
                    link?: string | null;
                    summary: {
                        __typename: 'TenderSummary';
                        size: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        };
                        generated: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        };
                        cost?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        savings?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        roi?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        monthly_savings?: number | null;
                    };
                    options: Array<{
                        __typename: 'TenderOptions';
                        cost?: number | null;
                        payment?: number | null;
                        maintenance?: number | null;
                        savings: number;
                    }>;
                    type: TENDER_TYPE;
                } | null;
                advisory_call_date?: string | null;
                advisory_call_time?: string | null;
            } | null;
            integrations?: {
                __typename: 'Integrations';
                trengo?: {
                    __typename: 'Trengo';
                    tickets: Array<{
                        __typename: 'Ticket';
                        id?: number | null;
                        contact?: number | null;
                        profile?: number | null;
                        directory: string;
                        channel: CHANNELS;
                        type: CONTACT_TYPE;
                        template?: TEMPLATE | null;
                        error?: ERROR | null;
                        params?: Array<{
                            __typename: 'WhatsappParam';
                            key: string;
                            value: string;
                        }> | null;
                    }>;
                    ticket_id: Array<number | null>;
                } | null;
                hubspot?: {
                    __typename: 'Hubspot';
                    contact_id?: string | null;
                    deal_id?: string | null;
                    error?: ERROR | null;
                } | null;
                sendgrid?: {
                    __typename: 'Sendgrid';
                    template?: TEMPLATE | null;
                } | null;
            } | null;
            status?: {
                __typename: 'ProspectStatus';
                value: PROSPECT_STATUS;
                date: string;
            } | null;
            validated?: LEAD_VALIDATION | null;
            engagement?: LEAD_ENGAGEMENT | null;
            docs?: Array<{
                __typename: 'Doc';
                name: string;
                type: string;
                link?: string | null;
                category?: DOC_CATEGORY | null;
                deleted?: boolean | null;
                updated_at?: string | null;
            }> | null;
            tally?: {
                __typename: 'Tally';
                installation?: string | null;
                commissioning?: string | null;
                deposit?: number | null;
                lease?: number | null;
                size?: number | null;
                term?: TENDER_SELECTION | null;
                investor?: INVESTOR | null;
                start?: string | null;
                end?: string | null;
                contract_end?: string | null;
                rebate?: number | null;
                payment?: {
                    __typename: 'Payment';
                    method?: PAYMENT_METHOD | null;
                    bank?: BANK | null;
                    bank_account?: string | null;
                    bank_owner?: string | null;
                } | null;
                bills?: Array<{
                    __typename: 'Bill';
                    id: string;
                    type: BILL_TYPE;
                    status: BILL_STATUS;
                    raw: number;
                    total: number;
                    GST: number;
                    taxe: number;
                    send_date: string;
                    invoice_date: string;
                    due_date: string;
                    period_start?: string | null;
                    period_end?: string | null;
                    paid?: string | null;
                    rebate?: number | null;
                    method?: PAYMENT_METHOD | null;
                    remark?: string | null;
                    items?: Array<{
                        __typename: 'BillItem';
                        description: string;
                        amount: number;
                    }> | null;
                    counter?: number | null;
                }> | null;
                currency?: string | null;
                status?: CUSTOMER_STATUS | null;
                epc?: {
                    __typename: 'Company';
                    value: string;
                    label: string;
                } | null;
                price?: number | null;
                capex?: number | null;
                installation_start?: string | null;
                site_visit?: string | null;
                site_visit_type?: SITE_VISIT_TYPE | null;
                retailer?: RETAILER | null;
                contract?: FORM_STATUS | null;
                whatsapp?: boolean | null;
                ami?: AMI | null;
                sp?: FORM_STATUS | null;
                sp_no?: string | null;
                giro?: GIRO_STATUS | null;
                inverter?: boolean | null;
                inverter_type?: Array<string> | null;
                inverter_number?: number | null;
                panel?: Array<string> | null;
                panel_number?: number | null;
                tierPayment?: Array<{
                    __typename: 'PaymentTranche';
                    payment?: number | null;
                    paid: boolean;
                    issued: boolean;
                }> | null;
                buydown?: string | null;
                invoice_items?: Array<{
                    __typename: 'BillItem';
                    description: string;
                    amount: number;
                }> | null;
                e_giro?: {
                    __typename: 'EGiro';
                    status?: E_GIRO_STATUS | null;
                } | null;
                curlec?: {
                    __typename: 'Curlec';
                    status?: CURLEC_STATUS | null;
                } | null;
                nem_application_status?: NEM_APPLICATION_STATUS | null;
                epc_added_to_whatsapp?: boolean | null;
                tnb_stamp_fee_status?: TNB_STAMP_FEE_STATUS | null;
                installation_status?: INSTALLATION_STATUS | null;
                tnb_meter_change_date?: string | null;
                system_turn_on_date?: string | null;
                ami_meter_change_date?: string | null;
                installation_scheduled?: boolean | null;
                top_date?: string | null;
                upload_to_epc?: boolean | null;
            } | null;
            referral?: {
                __typename: 'Referral';
                name?: string | null;
            } | null;
            stages?: Array<{
                __typename: 'Stage';
                status: string;
                start: string;
                end?: string | null;
                duration?: number | null;
                category?: STAGE_CATEGORIES | null;
                id?: string | null;
            }> | null;
            created: {
                __typename: 'Created';
                vendee: string;
                lead?: string | null;
                prospect?: string | null;
                customer?: string | null;
            };
            updated?: string | null;
            comment?: string | null;
            cognito_user_id?: string | null;
            utm?: {
                __typename: 'Utm';
                source?: string | null;
                medium?: string | null;
                campaign?: string | null;
                term?: string | null;
                content?: string | null;
            } | null;
        }>;
    };
};

export type GetUploadUrlQueryVariables = {
    input: Array<DocInput>;
};

export type GetUploadUrlQuery = {
    getUploadUrl: {
        __typename: 'Upload';
        url: Array<string>;
        link: Array<string>;
        name: Array<string>;
    };
};

export type ListSaleQueryVariables = {
    country_code: COUNTRY_CODE;
};

export type ListSaleQuery = {
    listSale: {
        __typename: 'ListSale';
        total: number;
        sales: Array<{
            __typename: 'Profile';
            id: string;
            email: string;
            name: string;
            phone?: string | null;
            contact_method?: Array<CONTACT_METHOD> | null;
            family_name: string;
            company?: string | null;
            group: Array<string>;
            created: string;
        }>;
    };
};

export type GetAssetsUrlQueryVariables = {
    url: string;
};

export type GetAssetsUrlQuery = {
    getAssetsUrl: string;
};

export type ListBillsQueryVariables = {
    country_code: COUNTRY_CODE;
    status?: Array<BILL_STATUS> | null;
    payment?: Array<PAYMENT_METHOD> | null;
    type?: Array<BILL_TYPE> | null;
    investor?: Array<INVESTOR> | null;
    epc?: Array<string> | null;
    sort?: SORT | null;
    sort_field?: BILL_SORT_FIELD | null;
    search_term?: string | null;
    size: number;
    offset: number;
};

export type ListBillsQuery = {
    listBills: {
        __typename: 'ListBills';
        bills: Array<{
            __typename: 'Bills';
            customer_id?: string | null;
            pk: string;
            invoice_id: string;
            invoice_date?: string | null;
            send_date?: string | null;
            due_date?: string | null;
            period_start?: string | null;
            period_end?: string | null;
            paid?: string | null;
            raw?: number | null;
            deposit?: number | null;
            total?: number | null;
            GST?: number | null;
            status: BILL_STATUS;
            type?: BILL_TYPE | null;
            rebate?: number | null;
            name?: string | null;
            family_name?: string | null;
            address?: string | null;
            zip?: string | null;
            capacity?: number | null;
            investor?: INVESTOR | null;
            installation_date?: string | null;
            commissioning_date?: string | null;
            billing_start?: string | null;
            contract_end?: string | null;
            term?: TENDER_SELECTION | null;
            billing_currency?: string | null;
            method?: PAYMENT_METHOD | null;
            bank?: string | null;
            bank_account?: string | null;
            bank_owner?: string | null;
            remark?: string | null;
            items?: Array<{
                __typename: 'BillItem';
                description: string;
                amount: number;
            }> | null;
            counter?: number | null;
            country_code?: COUNTRY_CODE | null;
            epc?: string | null;
        }>;
        total?: number | null;
        keys?: Array<number> | null;
    };
};

export type GetDashboardActivitiesQueryVariables = {
    country_code: COUNTRY_CODE;
    product?: Array<PRODUCT> | null;
    from: string;
    to: string;
};

export type GetDashboardActivitiesQuery = {
    getDashboardActivities: {
        __typename: 'ListActivities';
        activity: Array<{
            __typename: 'Activity';
            count: number;
            status: PROSPECT_STATUS;
            owner: string;
        }>;
    };
};

export type GetDashboardActivityDurationsQueryVariables = {
    country_code: COUNTRY_CODE;
    product?: Array<PRODUCT> | null;
    from: string;
    to: string;
};

export type GetDashboardActivityDurationsQuery = {
    getDashboardActivityDurations: {
        __typename: 'ListActivityDurations';
        duration: Array<{
            __typename: 'ActivityDuration';
            avg: number;
            median: number;
            min: number;
            max: number;
            status: PROSPECT_STATUS;
            owner: string;
        }>;
    };
};

export type GetDashboardApportionsQueryVariables = {
    country_code: COUNTRY_CODE;
    product?: Array<PRODUCT> | null;
    date: string;
};

export type GetDashboardApportionsQuery = {
    getDashboardApportions: {
        __typename: 'ListApportions';
        apportion: Array<{
            __typename: 'Apportion';
            count: number;
            status: PROSPECT_STATUS;
            owner: string;
        }>;
    };
};

export type GetVendeeQueryVariables = {
    country_code: COUNTRY_CODE;
    id: string;
};

export type GetVendeeQuery = {
    getVendee: {
        __typename: 'Vendee';
        country_code: COUNTRY_CODE;
        id: string;
        src?: SRC | null;
        ids?: {
            __typename: 'Ids';
            lead?: string | null;
            prospect?: string | null;
            customer?: string | null;
            internal?: string | null;
        } | null;
        product: PRODUCT;
        owner?: string | null;
        address: string;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        directory?: {
            __typename: 'Directory';
            contacts: Array<{
                __typename: 'Contact';
                id: string;
                name: string;
                family_name: string;
                company?: string | null;
                email?: string | null;
                phone?: string | null;
                method?: Array<CONTACT_METHOD> | null;
                type: CONTACT_TYPE;
            }>;
        } | null;
        tags?: Array<LABELS> | null;
        simulation?: {
            __typename: 'Simulation';
            timeframe?: number | null;
            ip?: {
                __typename: 'Ip';
                source?: string | null;
                country?: string | null;
            } | null;
            src?: number | null;
            installation: {
                __typename: 'Installation';
                roi: number;
                cost: {
                    __typename: 'HighLow';
                    high: number;
                    low: number;
                };
                generated: number;
                size: number;
                lease: string;
            };
            savings: {
                __typename: 'Savings';
                utilities: {
                    __typename: 'HighLow';
                    high: number;
                    low: number;
                };
                ppa: {
                    __typename: 'HighLow';
                    high: number;
                    low: number;
                };
            };
            impact: {
                __typename: 'Impact';
                cars: number;
                trees: number;
                co2: number;
            };
            rtoOptions?: Array<{
                __typename: 'RtoOption';
                cost: number;
                payment: number;
            }> | null;
        } | null;
        site?: {
            __typename: 'Site';
            address_components?: Array<{
                __typename: 'AddressComponent';
                long_name: string;
                short_name: string;
                types: Array<string | null>;
            }> | null;
            type: BUILDING_TYPE;
            storeys?: number | null;
            height?: number | null;
            eui?: number | null;
            energy?: ENERGY | null;
            consumption: number;
            location: Array<number>;
            osm_id?: number | null;
            material: ROOF_MATERIAL;
            polygon: Array<{
                __typename: 'Polygon';
                lng: number;
                lat: number;
            }>;
            area: number;
            irradiation: number;
            utilities: {
                __typename: 'Utilities';
                input?: number | null;
                calculated?: number | null;
            };
            retailer?: RETAILER | null;
            daytime_consumption: number;
            daytime_consumption_rate: CONSUMPTION;
            meter_box?: METER_BOX | null;
        } | null;
        proffer?: {
            __typename: 'Proffer';
            comment?: string | null;
            sub_switchboard?: SUB_SWITCHBOARD | null;
            meter_box?: METER_BOX | null;
            occupancy?: OCCUPANCY | null;
            expected_date?: string | null;
            offers?: Array<TENDER_SELECTION> | null;
            epc?: Array<{
                __typename: 'Epc';
                company: {
                    __typename: 'Company';
                    value: string;
                    label: string;
                };
                index: number;
                status: EPC_STATUS;
                capacity?: number | null;
                cost?: number | null;
                module?: string | null;
                panel_number?: number | null;
                inverter?: string | null;
                inverter_number?: number | null;
                scaffolding?: SCAFFOLDING | null;
                generated?: number | null;
                docs?: Array<{
                    __typename: 'Doc';
                    name: string;
                    type: string;
                    link?: string | null;
                    category?: DOC_CATEGORY | null;
                    deleted?: boolean | null;
                    updated_at?: string | null;
                }> | null;
                comment?: string | null;
                remark?: string | null;
                created: string;
                updated: string;
                delivery: string;
                reports?: Array<{
                    __typename: 'Report';
                    date: string;
                    text: string;
                }> | null;
            }> | null;
            tender?: {
                __typename: 'Tender';
                selection?: TENDER_SELECTION | null;
                url?: string | null;
                link?: string | null;
                summary: {
                    __typename: 'TenderSummary';
                    size: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    generated: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    };
                    cost?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    savings?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    roi?: {
                        __typename: 'HighLow';
                        high: number;
                        low: number;
                    } | null;
                    monthly_savings?: number | null;
                };
                options: Array<{
                    __typename: 'TenderOptions';
                    cost?: number | null;
                    payment?: number | null;
                    maintenance?: number | null;
                    savings: number;
                }>;
                type: TENDER_TYPE;
            } | null;
            advisory_call_date?: string | null;
            advisory_call_time?: string | null;
        } | null;
        integrations?: {
            __typename: 'Integrations';
            trengo?: {
                __typename: 'Trengo';
                tickets: Array<{
                    __typename: 'Ticket';
                    id?: number | null;
                    contact?: number | null;
                    profile?: number | null;
                    directory: string;
                    channel: CHANNELS;
                    type: CONTACT_TYPE;
                    template?: TEMPLATE | null;
                    error?: ERROR | null;
                    params?: Array<{
                        __typename: 'WhatsappParam';
                        key: string;
                        value: string;
                    }> | null;
                }>;
                ticket_id: Array<number | null>;
            } | null;
            hubspot?: {
                __typename: 'Hubspot';
                contact_id?: string | null;
                deal_id?: string | null;
                error?: ERROR | null;
            } | null;
            sendgrid?: {
                __typename: 'Sendgrid';
                template?: TEMPLATE | null;
            } | null;
        } | null;
        status?: {
            __typename: 'ProspectStatus';
            value: PROSPECT_STATUS;
            date: string;
        } | null;
        validated?: LEAD_VALIDATION | null;
        engagement?: LEAD_ENGAGEMENT | null;
        docs?: Array<{
            __typename: 'Doc';
            name: string;
            type: string;
            link?: string | null;
            category?: DOC_CATEGORY | null;
            deleted?: boolean | null;
            updated_at?: string | null;
        }> | null;
        tally?: {
            __typename: 'Tally';
            installation?: string | null;
            commissioning?: string | null;
            deposit?: number | null;
            lease?: number | null;
            size?: number | null;
            term?: TENDER_SELECTION | null;
            investor?: INVESTOR | null;
            start?: string | null;
            end?: string | null;
            contract_end?: string | null;
            rebate?: number | null;
            payment?: {
                __typename: 'Payment';
                method?: PAYMENT_METHOD | null;
                bank?: BANK | null;
                bank_account?: string | null;
                bank_owner?: string | null;
            } | null;
            bills?: Array<{
                __typename: 'Bill';
                id: string;
                type: BILL_TYPE;
                status: BILL_STATUS;
                raw: number;
                total: number;
                GST: number;
                taxe: number;
                send_date: string;
                invoice_date: string;
                due_date: string;
                period_start?: string | null;
                period_end?: string | null;
                paid?: string | null;
                rebate?: number | null;
                method?: PAYMENT_METHOD | null;
                remark?: string | null;
                items?: Array<{
                    __typename: 'BillItem';
                    description: string;
                    amount: number;
                }> | null;
                counter?: number | null;
            }> | null;
            currency?: string | null;
            status?: CUSTOMER_STATUS | null;
            epc?: {
                __typename: 'Company';
                value: string;
                label: string;
            } | null;
            price?: number | null;
            capex?: number | null;
            installation_start?: string | null;
            site_visit?: string | null;
            site_visit_type?: SITE_VISIT_TYPE | null;
            retailer?: RETAILER | null;
            contract?: FORM_STATUS | null;
            whatsapp?: boolean | null;
            ami?: AMI | null;
            sp?: FORM_STATUS | null;
            sp_no?: string | null;
            giro?: GIRO_STATUS | null;
            inverter?: boolean | null;
            inverter_type?: Array<string> | null;
            inverter_number?: number | null;
            panel?: Array<string> | null;
            panel_number?: number | null;
            tierPayment?: Array<{
                __typename: 'PaymentTranche';
                payment?: number | null;
                paid: boolean;
                issued: boolean;
            }> | null;
            buydown?: string | null;
            invoice_items?: Array<{
                __typename: 'BillItem';
                description: string;
                amount: number;
            }> | null;
            e_giro?: {
                __typename: 'EGiro';
                status?: E_GIRO_STATUS | null;
            } | null;
            curlec?: {
                __typename: 'Curlec';
                status?: CURLEC_STATUS | null;
            } | null;
            nem_application_status?: NEM_APPLICATION_STATUS | null;
            epc_added_to_whatsapp?: boolean | null;
            tnb_stamp_fee_status?: TNB_STAMP_FEE_STATUS | null;
            installation_status?: INSTALLATION_STATUS | null;
            tnb_meter_change_date?: string | null;
            system_turn_on_date?: string | null;
            ami_meter_change_date?: string | null;
            installation_scheduled?: boolean | null;
            top_date?: string | null;
            upload_to_epc?: boolean | null;
        } | null;
        referral?: {
            __typename: 'Referral';
            name?: string | null;
        } | null;
        stages?: Array<{
            __typename: 'Stage';
            status: string;
            start: string;
            end?: string | null;
            duration?: number | null;
            category?: STAGE_CATEGORIES | null;
            id?: string | null;
        }> | null;
        created: {
            __typename: 'Created';
            vendee: string;
            lead?: string | null;
            prospect?: string | null;
            customer?: string | null;
        };
        updated?: string | null;
        comment?: string | null;
        cognito_user_id?: string | null;
        utm?: {
            __typename: 'Utm';
            source?: string | null;
            medium?: string | null;
            campaign?: string | null;
            term?: string | null;
            content?: string | null;
        } | null;
    };
};

export type ListLogsQueryVariables = {
    country_code: COUNTRY_CODE;
    vendee_id: string;
    category?: Array<LOG_CATEGORIES> | null;
    favourite: boolean;
    month: MONTH;
    year: string;
    from: number;
    size: number;
};

export type ListLogsQuery = {
    listLogs: {
        __typename: 'ListLogs';
        total: number;
        logs: Array<{
            __typename: 'Log';
            id: string;
            author?: {
                __typename: 'Author';
                username?: string | null;
                src: SRC;
            } | null;
            created?: string | null;
            changes?: Array<{
                __typename: 'Change';
                op: OP;
                path: string;
                value?: string | null;
            } | null> | null;
            content?: string | null;
            visit?: {
                __typename: 'Visit';
                pitch: string;
                access: string;
                installation: string;
                inverter: string;
                cabling: string;
                timeline: string;
                other: string;
                docs: Array<{
                    __typename: 'Doc';
                    name: string;
                    type: string;
                    link?: string | null;
                    category?: DOC_CATEGORY | null;
                    deleted?: boolean | null;
                    updated_at?: string | null;
                }>;
            } | null;
            category: LOG_CATEGORIES;
            stage?: {
                __typename: 'Stages';
                type: PROSPECT_STATUS;
                product?: PRODUCT | null;
                comment?: string | null;
                date?: string | null;
                url?: string | null;
                select?: Array<string> | null;
                price?: number | null;
                commission?: number | null;
                size?: number | null;
                retailer?: RETAILER | null;
                investor?: INVESTOR | null;
                term?: TENDER_SELECTION | null;
                down_payment?: number | null;
                lease?: number | null;
                installation_date?: string | null;
                capex?: number | null;
                fee?: {
                    __typename: 'Fee';
                    start?: number | null;
                    end?: number | null;
                    management?: number | null;
                } | null;
                irr?: number | null;
                docs?: Array<{
                    __typename: 'Doc';
                    name: string;
                    type: string;
                    link?: string | null;
                    category?: DOC_CATEGORY | null;
                    deleted?: boolean | null;
                    updated_at?: string | null;
                }> | null;
                tender?: {
                    __typename: 'Tender';
                    selection?: TENDER_SELECTION | null;
                    url?: string | null;
                    link?: string | null;
                    summary: {
                        __typename: 'TenderSummary';
                        size: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        };
                        generated: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        };
                        cost?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        savings?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        roi?: {
                            __typename: 'HighLow';
                            high: number;
                            low: number;
                        } | null;
                        monthly_savings?: number | null;
                    };
                    options: Array<{
                        __typename: 'TenderOptions';
                        cost?: number | null;
                        payment?: number | null;
                        maintenance?: number | null;
                        savings: number;
                    }>;
                    type: TENDER_TYPE;
                } | null;
                epc?: {
                    __typename: 'Company';
                    value: string;
                    label: string;
                } | null;
                advisory_call_date?: string | null;
                advisory_call_time?: string | null;
            } | null;
            favourite?: boolean | null;
            editor_content?: {
                __typename: 'ContentState';
                blocks?: Array<{
                    __typename: 'Block';
                    key?: string | null;
                    text?: string | null;
                    type?: string | null;
                    depth?: number | null;
                    inlineStyleRanges?: Array<{
                        __typename: 'InlineStyleRange';
                        offset?: number | null;
                        length?: number | null;
                        style?: string | null;
                    } | null> | null;
                    entityRanges?: Array<{
                        __typename: 'EntityRange';
                        offset?: number | null;
                        length?: number | null;
                        key?: number | null;
                    } | null> | null;
                    data?: {
                        __typename: 'Data';
                        id?: string | null;
                    } | null;
                } | null> | null;
                entityMap?: Array<{
                    __typename: 'Entity';
                    type?: string | null;
                    mutability?: string | null;
                    data?: {
                        __typename: 'EntityData';
                        mention?: {
                            __typename: 'MentionData';
                            value?: string | null;
                            name?: string | null;
                            label?: string | null;
                            id?: string | null;
                        } | null;
                    } | null;
                } | null> | null;
            } | null;
        }>;
    };
};

export type ListMilestonesQueryVariables = {
    country_code: COUNTRY_CODE;
    order?: SORT | null;
    sort?: MILESTONE_SORT_FIELD | null;
    from: number;
    size: number;
    search?: string | null;
    product?: Array<PRODUCT> | null;
    status?: Array<CUSTOMER_STATUS> | null;
    investor?: Array<INVESTOR> | null;
    epc?: Array<string> | null;
};

export type ListMilestonesQuery = {
    listMilestones: {
        __typename: 'ListMilestones';
        total: number;
        milestones: Array<{
            __typename: 'Milestones';
            id: string;
            ids?: {
                __typename: 'Ids';
                lead?: string | null;
                prospect?: string | null;
                customer?: string | null;
                internal?: string | null;
            } | null;
            product: PRODUCT;
            address: string;
            zip?: string | null;
            state?: string | null;
            directory: {
                __typename: 'Directory';
                contacts: Array<{
                    __typename: 'Contact';
                    id: string;
                    name: string;
                    family_name: string;
                    company?: string | null;
                    email?: string | null;
                    phone?: string | null;
                    method?: Array<CONTACT_METHOD> | null;
                    type: CONTACT_TYPE;
                }>;
            };
            tally?: {
                __typename: 'Tally';
                installation?: string | null;
                commissioning?: string | null;
                deposit?: number | null;
                lease?: number | null;
                size?: number | null;
                term?: TENDER_SELECTION | null;
                investor?: INVESTOR | null;
                start?: string | null;
                end?: string | null;
                contract_end?: string | null;
                rebate?: number | null;
                payment?: {
                    __typename: 'Payment';
                    method?: PAYMENT_METHOD | null;
                    bank?: BANK | null;
                    bank_account?: string | null;
                    bank_owner?: string | null;
                } | null;
                bills?: Array<{
                    __typename: 'Bill';
                    id: string;
                    type: BILL_TYPE;
                    status: BILL_STATUS;
                    raw: number;
                    total: number;
                    GST: number;
                    taxe: number;
                    send_date: string;
                    invoice_date: string;
                    due_date: string;
                    period_start?: string | null;
                    period_end?: string | null;
                    paid?: string | null;
                    rebate?: number | null;
                    method?: PAYMENT_METHOD | null;
                    remark?: string | null;
                    items?: Array<{
                        __typename: 'BillItem';
                        description: string;
                        amount: number;
                    }> | null;
                    counter?: number | null;
                }> | null;
                currency?: string | null;
                status?: CUSTOMER_STATUS | null;
                epc?: {
                    __typename: 'Company';
                    value: string;
                    label: string;
                } | null;
                price?: number | null;
                capex?: number | null;
                installation_start?: string | null;
                site_visit?: string | null;
                site_visit_type?: SITE_VISIT_TYPE | null;
                retailer?: RETAILER | null;
                contract?: FORM_STATUS | null;
                whatsapp?: boolean | null;
                ami?: AMI | null;
                sp?: FORM_STATUS | null;
                sp_no?: string | null;
                giro?: GIRO_STATUS | null;
                inverter?: boolean | null;
                inverter_type?: Array<string> | null;
                inverter_number?: number | null;
                panel?: Array<string> | null;
                panel_number?: number | null;
                tierPayment?: Array<{
                    __typename: 'PaymentTranche';
                    payment?: number | null;
                    paid: boolean;
                    issued: boolean;
                }> | null;
                buydown?: string | null;
                invoice_items?: Array<{
                    __typename: 'BillItem';
                    description: string;
                    amount: number;
                }> | null;
                e_giro?: {
                    __typename: 'EGiro';
                    status?: E_GIRO_STATUS | null;
                } | null;
                curlec?: {
                    __typename: 'Curlec';
                    status?: CURLEC_STATUS | null;
                } | null;
                nem_application_status?: NEM_APPLICATION_STATUS | null;
                epc_added_to_whatsapp?: boolean | null;
                tnb_stamp_fee_status?: TNB_STAMP_FEE_STATUS | null;
                installation_status?: INSTALLATION_STATUS | null;
                tnb_meter_change_date?: string | null;
                system_turn_on_date?: string | null;
                ami_meter_change_date?: string | null;
                installation_scheduled?: boolean | null;
                top_date?: string | null;
                upload_to_epc?: boolean | null;
            } | null;
            docs?: Array<{
                __typename: 'Doc';
                name: string;
                type: string;
                link?: string | null;
                category?: DOC_CATEGORY | null;
                deleted?: boolean | null;
                updated_at?: string | null;
            }> | null;
            created: string;
            updated: string;
            cognito_user_id?: string | null;
        }>;
    };
};

export type GetClosedWonStageLogQueryVariables = {
    country_code: COUNTRY_CODE;
    vendee_id: string;
};

export type GetClosedWonStageLogQuery = {
    getClosedWonStageLog?: {
        __typename: 'ClosedWonStageLog';
        id: string;
        country_code: COUNTRY_CODE;
        product?: PRODUCT | null;
        closed_won_date?: string | null;
        price?: number | null;
        commission?: number | null;
        size?: number | null;
        retailer?: RETAILER | null;
        investor?: INVESTOR | null;
        term?: TENDER_SELECTION | null;
        down_payment?: number | null;
        lease?: number | null;
        installation_date?: string | null;
        capex?: number | null;
        fee?: {
            __typename: 'Fee';
            start?: number | null;
            end?: number | null;
            management?: number | null;
        } | null;
        docs?: Array<{
            __typename: 'Doc';
            name: string;
            type: string;
            link?: string | null;
            category?: DOC_CATEGORY | null;
            deleted?: boolean | null;
            updated_at?: string | null;
        }> | null;
        irr?: number | null;
        epc?: {
            __typename: 'Company';
            value: string;
            label: string;
        } | null;
    } | null;
};

export type ListTriggeredNotificationsQueryVariables = {
    country_code: COUNTRY_CODE;
    input: SetMilestoneInput;
};

export type ListTriggeredNotificationsQuery = {
    listTriggeredNotifications: Array<NOTIFICATION_TYPE>;
};

export type OnConnectSubscription = {
    onConnect?: {
        __typename: 'Integration';
        id: string;
        type: INTEGRATION_TYPE;
        keys?: Array<string> | null;
        error?: ERROR | null;
        country_code: COUNTRY_CODE;
    } | null;
};
